/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { Calendar } from '../model/models';
import { HideFromPortfolioRequest } from '../model/models';
import { ProfessionalServiceResponse } from '../model/models';
import { Project } from '../model/models';
import { ProjectResponse } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface ProfileServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param id 
     */
    profileGetProfessionalSummaryDetails(id: string, extraHttpRequestParams?: any): Observable<ProfessionalServiceResponse>;

    /**
     * 
     * 
     * @param id 
     */
    profileGetProjectDetails(id: string, extraHttpRequestParams?: any): Observable<Project>;

    /**
     * 
     * 
     * @param userId 
     * @param startDate 
     * @param endDate 
     */
    profileGetUserCalendar(userId: string, startDate?: Date, endDate?: Date, extraHttpRequestParams?: any): Observable<Array<Calendar>>;

    /**
     * 
     * 
     * @param userId 
     * @param query 
     * @param page 
     * @param size 
     */
    profileGetUserProfessionalServices(userId: string, query?: string, page?: number, size?: number, extraHttpRequestParams?: any): Observable<Array<ProfessionalServiceResponse>>;

    /**
     * 
     * 
     * @param userId 
     * @param query 
     * @param page 
     * @param size 
     */
    profileGetUserProjects(userId: string, query?: string, page?: number, size?: number, extraHttpRequestParams?: any): Observable<Array<ProjectResponse>>;

    /**
     * 
     * 
     * @param projectId 
     * @param request 
     */
    profileHideFromPortfolio(projectId: string, request: HideFromPortfolioRequest, extraHttpRequestParams?: any): Observable<Project>;

}
