import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '@seech/ux-ng';
import { Location } from '@angular/common';
import { of, Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { LoaderService } from '../../../../services/loader.service';

@Component({
  selector: 'app-general-modal',
  templateUrl: './general-modal.component.html',
  styleUrls: ['./general-modal.component.scss'],
})
export class GeneralModalComponent implements OnInit, OnDestroy {
  @Input() hasHeader = true;
  @Input() label!: string;
  @Input() padding?: string;
  @Input() paddingTop?: string;
  @Input() alignment: 'left' | 'center' = 'center';
  sub: Subscription = new Subscription();
  backButtonDisabled = true;
  cdnBaseUrl = environment.cdnBaseUrl;
  $isLoading = of(false);

  constructor(
    private modalService: ModalService,
    private location: Location,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.$isLoading = this.loaderService.isLoading.asObservable();
    history.pushState(null, '');
    this.sub.add(
      this.location.subscribe(() => {
        this.backButtonDisabled = false;
        this.closeModal();
      })
    );
  }

  closeModal(data?: unknown) {
    this.modalService.close(null, data);
  }

  ngOnDestroy(): void {
    if (this.backButtonDisabled) history.back();
    this.sub.unsubscribe();
  }
}
