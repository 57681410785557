/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Geometry } from './geometry';
import { Project } from './project';
import { Qualification } from './qualification';
import { Participant } from './participant';
import { ProfessionalSummary } from './professionalSummary';
import { ServiceOfInterest } from './serviceOfInterest';
import { Coordinates } from './coordinates';
import { SavedEntity } from './savedEntity';
import { Setting } from './setting';
import { ProfessionalService } from './professionalService';
import { Bid } from './bid';
import { Session } from './session';


export interface User { 
    coordinates: Coordinates;
    totalCompletedJobs: number;
    roleId: string;
    participants?: Array<Participant>;
    clientProjects: Array<Project>;
    professionalProjects: Array<Project>;
    bids: Array<Bid>;
    id: string;
    globalId: string;
    intentId?: string;
    username: string;
    name: string;
    email: string;
    location?: string;
    geolocation?: Geometry;
    bio?: string;
    imageURL?: string;
    isProfessional?: boolean;
    phoneNumber?: string;
    notificationLastViewedOn?: Date;
    createdOn: Date;
    lastSeen: Date;
    deletedOn?: Date;
    isDeleted?: boolean;
    deactivatedOn?: Date;
    isDeactivated?: boolean;
    professionalServices: Array<ProfessionalService>;
    professionalSummary?: ProfessionalSummary;
    qualifications: Array<Qualification>;
    savedEntities: Array<SavedEntity>;
    serviceOfInterests: Array<ServiceOfInterest>;
    sessions: Array<Session>;
    settings: Array<Setting>;
}

