/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Tag } from './tag';


export interface EntityTag { 
    isDeleted: boolean;
    id: string;
    tagId: string;
    createdOn: Date;
    entityId: string;
    appId: string;
    parentName: string;
    tag: Tag;
}

