/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Coordinates, Intent, LocationService, LookupService, OnboardingService, ServiceRequest, UserRequest, UserService } from '@profindar/shared-ng';
import { debounceTime, Subject } from 'rxjs';
import { CONSTANT } from '../../../constants';
import { environment } from '../../../../environments/environment';
import { ModalService, ToastService } from '@seech/ux-ng';
import { Geolocation, LocationComponent, MAPTYPE } from '@seech/controls-ng';
import { SessionService } from '../../../services/session.service';


@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
  animations: [
    trigger('slideAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-out', style({ transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-out', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})

export class OnboardingComponent implements OnInit, AfterViewInit {

  @ViewChild(LocationComponent) location!: LocationComponent;
  servicesToolsTechSuggestions: any[] = [];
  private addserviceSubject = new Subject<string | string[]>();
  servicesArray: any[] = [];
  serviceArrayError = '';
  acceptedservicesArrayLength = 10;
  servicesSuggestions: any[] = [];
  selectedservices = '';
  intents: Intent[] = []
  cdnBaseUrl = environment.cdnBaseUrl;
  results: any[] = [];
  currentIndex = 0;
  slideRight = false;
  slideLeft = false;
  constant = CONSTANT;
  intentName = "";
  pageCount = [0, 1, 2];
  selectedIntent: Intent = <Intent>{};
  savingIntent = false;
  loading = false;
  noSelectedLocation = true;
  saving = false;
  coordinates: { latitude: number, longitude: number } | null = null;
  error: string | null = null;
  locationValue: any = {};
  MAPTYPE = MAPTYPE;
  markers: any[] = [];
  value: string | string[] | any = '';
  selectedLocation: any = {};
  currentLocation: any = {};
  minimumRequiredServices = 3;
  serviceError = ''
  inputValue: any[]=[]
  //trackingCurrentLocation = false;

  constructor(private cdr: ChangeDetectorRef, private lookupService: LookupService, 
              private onboardingService: OnboardingService, private modalService: ModalService,
              private toastService: ToastService, private userService: UserService, 
              private sessionService: SessionService){}


  ngAfterViewInit(): void {
     this.location.getCurrentLocation()
  }

  ngOnInit(): void {
    this.getServices();
    this.getUserIntents();

    this.addserviceSubject.pipe(
      debounceTime(300)
    ).subscribe((service: any) => this.processAddservice(service));
  }

  /* Page selector */
  nextSlide() {
    this.slideRight = true;
    setTimeout(() => {
      this.currentIndex = (this.currentIndex + 1) % this.pageCount.length; 
      this.slideRight = false;
    }, 300); 
  }

  prevSlide() {
    this.slideLeft = true;
    this.slideRight = false;
    setTimeout(() => {
      this.currentIndex = (this.currentIndex - 1 + this.pageCount.length) % this.pageCount.length; 
      this.slideLeft = false;
    }, 300); 
  }

  selectCard(intent: Intent){
    this.selectedIntent = intent;
    if(intent.name === CONSTANT.INTENT.CLIENT){
      this.intentName = CONSTANT.INTENT.CLIENT
    }

    if(intent.name === CONSTANT.INTENT.PROFESSIONAL){
      this.intentName = CONSTANT.INTENT.PROFESSIONAL
    }
  }

  getServices() {
    this.loading = true; 
      this.lookupService.services('', 0, 100).subscribe({
        next: (services) => {
          this.servicesSuggestions = services;
          const suggestions = this.servicesSuggestions.map((suggestion) => {
            return {
              name: suggestion.name,
              value: suggestion.description,
              id: suggestion.id,
            };
          });

          this.servicesToolsTechSuggestions = [
            ...this.servicesToolsTechSuggestions,
            ...suggestions,
          ];

          this.loading = false; 
          this.cdr.detectChanges();
        },
        error: () => { },
        complete: () => {
          this.loading = false;
      },
    });
  }

  /* Intent mothods*/
  getUserIntents(){
    this.lookupService.getIntents().subscribe({
      next: (resData) => {
        const result = resData.filter(i => i.name.toLowerCase() !== CONSTANT.INTENT.VENDOR.toLowerCase())
        this.intents = result
        this.intentName = this.intents[0].name
        this.selectedIntent = this.intents[0]
        }, error: () => {
      }
    })
  }

  saveIntent(){
    this.savingIntent = true;
    this.onboardingService.addUserIntent(this.selectedIntent.id).subscribe({
      next: (response)=> {
        if(response){
          response.entityId
          this.savingIntent = false;
          this.nextSlide()
        }
      }, error: (err) => {
        console.log(err); 
        this.toastService.error('Unable to save your preference.');  
      },
      complete: () => {
        this.savingIntent = false
      },
    })
  }

  useCurrentLocation(){
    this.location.getCurrentLocation()
    this.noSelectedLocation = false;
    //this.selectedLocation = this.currentLocation

   // this.value = this.selectedLocation.location
   // this.cdr.detectChanges();
  }

  selectLocation(){
    this.noSelectedLocation = false;
  }

  retrieveSelectedLocation(event: Geolocation) {
      // Add marker for the newly selected location
      this.selectedLocation = event.coordinates

      // if(!this.trackingCurrentLocation){
      //   this.currentLocation = event.coordinates
      //   this.trackingCurrentLocation = true
      // }
     
      this.markers = [{
        position: { lat: event.coordinates.latitude, lng: event.coordinates.longitude },
        title: event.coordinates.location,
      }];

      // Update map center to the new location
      this.locationValue = {
        location: event.coordinates.location,
        latitude: event.coordinates.latitude,
        longitude: event.coordinates.longitude,
      };
  }
  
  onMapClick(event: any) {}
 
  saveLocation() {
      if(!this.noSelectedLocation){
        this.loading = true; this.noSelectedLocation = true //disable button on save
        
        const { location, latitude, longitude } = this.selectedLocation;

        const coord: Coordinates = {
          location,
          latitude,
          longitude,
          coords: `${latitude},${longitude}`
        };

        const userRequest: UserRequest = {
          username: this.sessionService.getCurrentUsername() as string,
          bio: this.sessionService.getCurrentUserBio() as string,
          imageURL: this.sessionService.getCurrentUserImageURL() as string,
          phoneNumber: this.sessionService.getCurrentUserPhoneNumber() as string,
          coordinates: coord
        };

        this.userService.updateProfile(userRequest).subscribe({
          next: () => {
            this.loading = false; this.noSelectedLocation = false; 
            this.nextSlide();
          },
          complete: () => {
            this.loading = false; this.noSelectedLocation = false;
          }
        });
      }
  }

  /* Services and service */
  saveServices(){
    if(this.servicesArray.length < this.minimumRequiredServices){
      this.serviceError = `You must add at least ${this.minimumRequiredServices} services`
      return
    }

    this.saving = true
    const serviceIds: string[] = []
    this.servicesArray.forEach(service=>{
      serviceIds.push(service.id)
    })
    
    this.onboardingService.addServicesOfInterest(serviceIds).subscribe({
      next: (response) => {
        if(response){
          this.modalService.close()
          this.toastService.success('Account setup completed');
        }
      },
      complete: () => {
        this.saving = false;
      },
    })
  }

  handleSelectionChange(event: any): void {
    this.serviceError = ''
    if (event) {
      this.inputValue = event.map((s: any) => s.name); //input chip value
      event.forEach((e: any) => {
        this.addservice(e.value);
      });
    }
  }
 
  addservice(service: string | string[]) {
    this.addserviceSubject.next(service);
  }

  processAddservice(service: string | string[]) {
    if (Array.isArray(service)) {
      // If service is an array, process each service in the array
      service.forEach((s) => this.addSingleservice(s));
    } else {
      // If service is a single string, process it directly
      this.addSingleservice(service);
    }
  }

  private addSingleservice(service: string) {
    service = service.trim();

    if (this.servicesArray.length > this.acceptedservicesArrayLength) {
      return;
    }

    // Check if the service already exists in servicesArray
    const existingserviceInArray = this.servicesArray.find(
      (existingservice) => existingservice.value === service
    );
    if (existingserviceInArray) {
      this.serviceArrayError = 'service already exists.';
      return;
    }

    const existingservice = this.servicesToolsTechSuggestions.find(
      (suggestion) => suggestion.name === service
    );

    if (existingservice) {
      const newservice = {
        id: existingservice.id,
        name: service,
        value: service,
      };

      this.servicesArray = [newservice, ...this.servicesArray];
    } else {
      const payload: ServiceRequest = {
        name: service,
        description: service,
        jobTitle: service,
      };

      this.lookupService.addService(payload).subscribe({
        next: (resData) => {
          const addedservice = {
            id: resData.id,
            name: resData.description,
            value: resData.description,
          };

          this.servicesArray = [addedservice, ...this.servicesArray];
          this.cdr.detectChanges();
        },
      });
    }

      this.serviceArrayError = '';
      this.cdr.detectChanges();
  }

  onInputChanged(event: any): void { //perform service search
    this.lookupService.services(event, 0, 100).subscribe({
      next: (services) => {
        this.servicesSuggestions = services;
        const suggestions = this.servicesSuggestions.map((suggestion) => {
            return {
              name: suggestion.name,
              value: suggestion.description,
              id: suggestion.id,
            };
          });
         
          this.servicesToolsTechSuggestions = [...suggestions];
          //console.log("services", this.servicesToolsTechSuggestions)
          this.cdr.detectChanges()
          
        },
        error: () => { },
        complete: () => {
      },
    });
  }
  

  listenToSelectionLimit(event: boolean) {
     if (event) {
      this.serviceArrayError = `services cannot be more than ${this.acceptedservicesArrayLength}`;
      } 
      else if (!event) {
      this.serviceArrayError = '';
    }
  }
 
}
