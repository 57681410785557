/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EventParticipant } from './eventParticipant';


export interface ProjectResponse { 
    id: string;
    referenceId: string;
    statusId: string;
    professionalId: string;
    createdBy: string;
    description: string;
    status: string;
    payRateType?: string;
    role: string;
    currency?: string;
    budget?: number;
    location?: string;
    startedOn: Date;
    createdOn: Date;
    statusInitiatedOn: Date;
    completedOn?: Date;
    proposedEndDate?: Date;
    services: Array<string>;
    tasks: Array<string>;
    eventParticipant: EventParticipant;
    hideFromPortfolio?: boolean;
}

