/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProfessionalSkillRequest } from './professionalSkillRequest';
import { Coordinates } from './coordinates';


export interface ProfessionalServiceRequest { 
    id: string;
    serviceId: string;
    description?: string;
    isRemote?: boolean;
    professionalLocation?: boolean;
    clientLocation?: boolean;
    currency?: string;
    rate?: number;
    rateTypeId?: string;
    coordinates: Coordinates;
    professionalSkills?: Array<ProfessionalSkillRequest>;
}

