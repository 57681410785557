<section id="onboarding-modal">
    <app-general-modal label="">
        <div>
            <!-- Carousel Wrapper -->
            <div class="carousel-container">
                <div class="header mb-[-5px]">
                    <div>
                        <i  *ngIf="currentIndex !== 0" class="mr-2 text-[24px] si-prev-arrow  cursor-pointer" ng-reflect-name="prev-arrow" data-icon="outline" (click)="prevSlide()"></i>
                    </div>
                    <div class="text-wrapper">
                        <span class="pro text-secondary">Pro</span> 
                        <span class="findar text-black">Findar</span>
                    </div>
                </div>
                
                <div class="cards">
                    <!-- First Carousel -->
                    <div *ngIf="currentIndex === 0" [ngClass]="{'active': currentIndex === 0, 'slide-right': slideRight, 'slide-left': slideLeft}" class="slide mt-3">
                        <!-- <div class="flex flex-row justify-between mt-1 w-full mb-[-21px] mt-3 mb-1">
                                <button sch-button class="!text-[13px]" type="submit"  class="location-button" (click)="useCurrentLocation()">
                                    <span>Use current location</span>
                                </button>
                        </div> -->
                        <div>
                            <seech-location
                                [results] = [] 
                               
                                [id]="'currentIndex'" 
                                label=""
                                (placeSelected)="retrieveSelectedLocation($event)"
                                (change)="selectLocation()"
                                [chipsColor]="'success'"
                            />
                        </div>
                        <div class="my-4">
                            <div class="col-4">
                                <div class="search-container">
                                    <button
                                        sch-button
                                        shape="rounded"
                                        class="go-search"
                                        color="secondary"
                                        (click)="useCurrentLocation()"
                                    >
                                    <i [sch-icon]="'geotag'" class="seech-icon"></i>
                                    </button>
                                </div>
                            </div>
                            <seech-map 
                                [mapEnabled]="true"
                                [streetViewControl]="false"
                                [fullscreenControl]="false"
                                [center]="{ lat: locationValue?.latitude, lng: locationValue?.longitude }"
                                [zoom]="12" 
                                [mapTypeId]="MAPTYPE.ROADMAP" 
                                [mapDraggable]="true"
                                [disableDefaultUI]="false" 
                                [markers]="markers" 
                                (mapClick)="onMapClick($event)" />
                        </div>
                        <div class="map-footer mb-[-5px]">
                            <div class="icon-container">
                                <div *ngFor="let icon of pageCount; let i = index">
                                    <div style="margin-left: -17px">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="50px" viewBox="100 -960 760 960"  [attr.fill]="currentIndex === i ? '#077D0B' : '#00942954'">
                                            <path d="M480-120q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29Z"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div class="flex gap-2">
                                <div class="mt-2 text-background cursor-pointer" [class.disabled]="loading" (click)="!loading && nextSlide()">Skip</div>
                                <div class="save-button">
                                    <button sch-button class="!text-[13px] submit-btn" type="submit" (click)="saveLocation()" style="border-radius: 5px;" [disabled]="noSelectedLocation">
                                        <span>Save</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
            
                    <!-- Second Carousel -->
                    <div *ngIf="currentIndex === 1" [ngClass]="{'active': currentIndex === 1, 'slide-right': slideRight, 'slide-left': slideLeft}" class="slide">
                        <div class="my-4">
                            <div class="wrapper">
                                <div class="flex justify-center text-sm font-medium font-semibold">Are you Joining as a Client or Professional</div>
                                <div class="cards flex flex-wrap justify-center gap-3 items-center mt-3">
                                    <div *ngFor="let intent of intents; let i = index" class="flex flex-col">
                                        <div class="existing cursor-pointer" (click)="selectCard(intent)">
                                            <div 
                                                class="outline-star border-edges items-center flex flex-col justify-center border rounded-[3.20px] 
                                                w-[164px] h-[154px] sm:w-[140px] sm:h-[130px] md:w-[180px] md:h-[160px]" 
                                                [ngClass]="{
                                                    'border-secondary': intent.name === selectedIntent?.name, 
                                                    'border-green-500': intent.name === selectedIntent?.name
                                                }"
                                            >
                                                <img *ngIf="intent.name === constant.INTENT.CLIENT" src="{{cdnBaseUrl}}images/onboardingclient.png" />
                                                <img *ngIf="intent.name === constant.INTENT.PROFESSIONAL" src="{{cdnBaseUrl}}images/onboardingprofessional.png" />
                                                <div class="text-sm mt-1 text-center p-2">{{intent.description}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-col justify-center items-center mt-3">
                                <div>
                                    <button sch-button [disabled]="savingIntent" class="!text-[15px]" type="submit" (click)="saveIntent()" style="border-radius: 25px;">
                                        Continue <span class="lowercase-text">as</span><span class="lowercase-text"> a </span> {{intentName}}
                                    </button>
                                </div>
                                <div class="mt-2 text-background cursor-pointer"  [class.disabled]="savingIntent" (click)="!savingIntent && nextSlide()">Skip</div>
                            </div>
                            <div class="icon-container client-pro-margin">
                                <div *ngFor="let icon of pageCount; let i = index">
                                    <div style="margin-left: -17px">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="50px" viewBox="100 -960 760 960"  [attr.fill]="currentIndex >= i ? '#077D0B' : '#00942954'">
                                            <path d="M480-120q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29Z"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                     <!-- Third Carousel -->
                     <div *ngIf="currentIndex === 2" [ngClass]="{'active': currentIndex === 2, 'slide-right': slideRight, 'slide-left': slideLeft}" class="slide">
                        <div class="my-4">
                            <div class="wrapper">
                                <div class="flex justify-center text-sm font-medium font-semibold">Choose {{minimumRequiredServices}} or more services you are interested in</div>

                                <div class="input-with-icon justify-center items-center mt-3 w-100">
                                    <seech-combo-select
                                    nameKey="name"
                                    valueKey="value"
                                    autocomplete="off"
                                    [value]="inputValue"
                                    [results]="servicesToolsTechSuggestions"
                                    (selectedChange)="handleSelectionChange($event)"
                                    (searchTextChanged)="onInputChanged($event)"
                                    filterKey="name"
                                    [id]="'skills-input'"
                                    [label]=""
                                    [chipsColor]="'success'"
                                    [maxRowCount]="5"
                                    [maxSelectionCount]="acceptedservicesArrayLength"
                                   
                                    (selectionLimitReached)="
                                      listenToSelectionLimit($event)
                                    "
                                    [iClass]="'combo-icon'"
                                  >
                                    <!-- <i
                                      *ngIf="
                                        servicesToolsTechSuggestions.length < 1 && servicesValue !== ''
                                      "
                                      (click)="addservice(servicesValue)"
                                      [sch-icon]="'plus'"
                                      class="sch-input-icon"
                                      action-icon
                                    ></i> -->
                                    </seech-combo-select>
                                </div>
                                <seech-validation *ngIf="serviceError" type="error"
                                [message]="serviceError"></seech-validation>
                            </div>
                        </div>
                        <div class="flex flex-col justify-center items-center mt-3">
                            <div class="submit w-80">
                            <button sch-button class="w-80 !text-[15px] submit-btn" [disabled]="saving" type="submit" (click)="saveServices()"  style="border-radius: 25px;">
                                Let's Go
                            </button>
                            </div>
                        </div>
                        <div class="icon-container">
                            <div *ngFor="let icon of pageCount; let i = index">
                                <div style="margin-left: -17px">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="50px" viewBox="100 -960 760 960"  [attr.fill]="currentIndex >= i ? '#077D0B' : '#00942954'">
                                        <path d="M480-120q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29Z"/>
                                    </svg>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </app-general-modal>
</section>
          
        
        


