/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TaskChangeRequest } from './taskChangeRequest';
import { PaymentSchedule } from './paymentSchedule';
import { PaymentInstallmentChangeRequest } from './paymentInstallmentChangeRequest';
import { PaymentScheduleRelationship } from './paymentScheduleRelationship';
import { PaymentInstallmentRelationship } from './paymentInstallmentRelationship';


export interface PaymentScheduleChangeRequestResponse { 
    projectId: string;
    oldPaymentSchedule: PaymentSchedule;
    newPaymentSchedule: PaymentSchedule;
    paymentScheduleRelationship: PaymentScheduleRelationship;
    paymentInstallmentRelationships: Array<PaymentInstallmentRelationship>;
    paymentInstallmentChangeRequest: Array<PaymentInstallmentChangeRequest>;
    taskChangeRequest: Array<TaskChangeRequest>;
    canPerformChangeRequest: boolean;
}

