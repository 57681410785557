import { inject } from '@angular/core';
import { CanActivateChildFn } from '@angular/router';
import { LayoutService } from '../layout.service';

export const layoutGuard: CanActivateChildFn = (route, segments) => {
  const layoutService = inject(LayoutService);
  
  if (route.data && route.data['layout']) {
    layoutService.layoutData = { ...layoutService.defaultLayoutConfig, ...route.data['layout'] };
  } else {
    layoutService.useDefaultConfig();
  }
  
  return true;
};
