<section class="becomea-pro w-full">
  <app-general-modal style="width: 100%" label="Register New Service">
    <div
      *ngIf="!formSubmitted"
      class="become-pro-form w-full lg:!w-full p-[.8rem] px-1"
    >
      <ng-container *ngIf="payRateTypes.length === 0">
        <div class="h-[25rem] w-[30rem] flex items-center justify-center">
          <seech-spinner
            [id]="'services-spinner-secondary'"
            [color]="'secondary'"
          >
          </seech-spinner>
        </div>
      </ng-container>

      <form
        *ngIf="payRateTypes.length !== 0"
        class=""
        [formGroup]="addNewServiceForm"
      >
        <div class="mb-3">
          <!-- <p class="my-2 text-[11px] lg:text-[16px] text-[#48525F] font-[500]">
            Service Name / Job Title
          </p> -->

          <div class="w-full">
            <seech-autocomplete
              [results]="preservedServiceSuggestions"
              nameKey="name"
              valueKey="value"
              (selected)="handleServiceSelectionChange($event)"
              filterKey="name"
              formControlName="serviceId"
              [(value)]="servicesValue"
              (searchTextChanged)="onServiceInputValueChange($event)"
              [id]="'services'"
              (keydown.enter)="
                $event.preventDefault(); serviceIconClicked = true; addService()
              "
              [label]="'Service Name / Job Title'"
              [clearable]="false"
            >
              <!-- <i
                *ngIf="
                  preservedServiceSuggestions.length < 1 && !serviceIconClicked
                "
                (click)="serviceIconClicked = true; addService()"
                class="bg-secondary flex items-center justify-center font-light text-center text-white-100 w-10 h-10 sch-input-icon rounded-full"
                action-icon
              >
                <img src="{{ cdnBaseUrl }}images/custom-add-icon.svg" alt="" />
              </i> -->
            </seech-autocomplete>
            <seech-validation
              *ngIf="formControlValidityCheck('serviceId')"
              type="error"
              [message]="'Please select a service'"
            ></seech-validation>
          </div>
        </div>
        <div class="my-3">
          <!-- <p class="my-2 text-[11px] lg:text-[16px] text-[#48525F] font-[500]">
            Skills / Tools / Technologies
          </p> -->

          <div class="input-with-icon w-full">
            <seech-combo-select
              nameKey="name"
              valueKey="value"
              autocomplete="off"
              [results]="skillsSuggestions"
              [(value)]="skillsToolsTechValue"
              (selectedChange)="handleSelectionChange($event)"
              (searchTextChanged)="onInputValueChange($event)"
              filterKey="name"
              [id]="'skills-input'"
              label=" Skills / Tools / Technologies"
              (keyup.enter)="
                $event.preventDefault();
                addSkillsToolsTech(skillsToolsTechValue)
              "
              (keydown.tab)="
                $event.preventDefault();
                addSkillsToolsTech(skillsToolsTechValue)
              "
              [chipsColor]="'success'"
              [maxSelectionCount]="20"
              [iClass]="'combo-icon'"
              (chipsDeleteChange)="onSkillChipsDelete($event)"
              [disabled]="servicesValue !== '' && servicesValue ? false : true"
              (characterLimitReached)="onCharacterLimitReached($event)"
              [sch-character-limit]="true"
              [embedCounter]="false"
              [allowExceed]="false"
              [maxLength]="skillMaxLength"
            >
            </seech-combo-select>
            <seech-validation
              *ngIf="!isSkillsEntered"
              type="error"
              [message]="
                'Please select a skill, press Enter/Tab, or click the add button.'
              "
            ></seech-validation>
            <!-- <seech-validation
              *ngIf="isSkillNameLimitErr"
              type="error"
              [message]="
                'Maximum character limit of {{skillMaxLength}} exceeded.'
              "
            ></seech-validation> -->

            <div *ngIf="isFetchingSkills" class="w-full skills-loading">
              <seech-progress-bar
                [id]="'progress-3'"
                [progress]="30"
                [isIndeterminate]="true"
                [color]="'success'"
              ></seech-progress-bar>
            </div>
          </div>
        </div>
        <div class="my-3">
          <!-- <p
            class="my-2 text-[11px] lg:text-[16px] cursor-pointer text-[#48525F] font-[500]"
          >
            Currency
          </p> -->

          <div class="w-full">
            <seech-currency
              [id]="'add-new-service-currency'"
              [options]="currencyList"
              (selected)="onCurrencySelected($event)"
              formControlName="currency"
              (scrollEnd)="handlePagination()"
              (searchValueChange)="onSelectValueChange($event)"
              [selectedValue]="defaultCurrencyValue || {}"
              [label]="'Currency'"
            ></seech-currency>

            <seech-validation
              *ngIf="
                formControlValidityCheck('currency') || !isCurrencySelected
              "
              type="error"
              [message]="'Please select a currency'"
            ></seech-validation>
          </div>
        </div>
        <div class="my-2 w-full justify-between flex items-end">
          <div class="w-[48%]">
            <p class="sch-input-label">Pay Rate</p>

            <div class="w-full">
              <input
                class="w-full px-3"
                type="number"
                formControlName="payRate"
                min="0"
                (change)="onPayRateChange($event)"
              />
              <seech-validation
                *ngIf="formControlValidityCheck('payRate')"
                type="error"
                [message]="'Please enter a pay rate'"
              ></seech-validation>
            </div>
          </div>

          <div class="w-[48%]">
            <!-- <p
              class="my-2 text-[11px] lg:text-[16px] text-[#48525F] font-[500]"
            >
              Rate Type
            </p> -->
            <seech-select
              [options]="payRateTypes"
              formControlName="rateType"
              [disabled]="false"
              [selectedValue]="defaultRateTypeValue"
              (selected)="onRateTypeSelected($event)"
              [placeholder]="defaultRateTypeValue?.name || 'Select Rate Type'"
              [label]="'Rate Type'"
            >
            </seech-select>

            <seech-validation
              *ngIf="
                formControlValidityCheck('rateType') || !isRateTypeSelected
              "
              type="error"
              [message]="'Please select a rate type'"
            ></seech-validation>
          </div>
        </div>
        <div class="my-3">
          <!-- <textarea
            formControlName="description"
            class="!w-full"
            id=""
            cols="10"
            rows="5"
            placeholder="Describe your services in a few words"
            (input)="onTextAreaChange()"
            sch-character-limit
            [maxLength]="1024"
            [threshold]="80"
            [embedCounter]="true"
            [allowExceed]="false"
          ></textarea> -->

          <seech-textarea
            formControlName="description"
            [maxLength]="1024"
            [threshold]="80"
            [allowExceed]="false"
            (input)="onTextAreaChange()"
            [id]="'description'"
            [label]="'Description'"
            [embedCounter]="true"
            [variant]="'classic'"
          >
          </seech-textarea>

          <!-- <div class="character-counter">
            {{ currentCharacterCount }}/{{ maxLength }}
          </div> -->

          <seech-validation
            *ngIf="formControlValidityCheck('description')"
            type="error"
            [message]="'Please enter a description'"
          ></seech-validation>
        </div>
        <div class="mb-3">
          <!-- <p class="my-2 text-[11px] lg:text-[16px] text-[#48525F] font-[500]">
            Location
          </p> -->

          <div class="w-full">
            <seech-location
              [coordinates]="this.payload.coordinates || {}"
              (placeSelected)="onLocationSelected($event)"
              [results]="[]"
              [id]="'location-input'"
              [chipsColor]="'success'"
              [label]="'Location'"
            >
            </seech-location>
            <!-- <input
              class="w-full px-3"
              type="text"
              formControlName="location"
            /> -->

            <seech-validation
              *ngIf="formControlValidityCheck('location')"
              type="error"
              [message]="'Please enter a location'"
            ></seech-validation>
          </div>
        </div>
        <div class="my-3">
          <div
            class="flex flex-col lg:flex-row items-start md:justify-between switch-gap-pro"
          >
            <seech-switch
              class="my-2 lg:my-0"
              label="Professional Location"
              formControlName="professionalLocation"
            ></seech-switch>
            <seech-switch
              class="my-2 lg:my-0"
              label="Mobile"
              formControlName="isMobile"
            ></seech-switch>
          </div>
        </div>
        <div class="my-3">
          <div class="flex flex-col lg:flex-row items-start md:justify-between">
            <seech-switch
              class="my-2 lg:my-0"
              label="Client Location"
              formControlName="clientLocation"
            ></seech-switch>
            <seech-switch
              class="my-2 lg:my-0"
              label="Remote"
              formControlName="isRemote"
            ></seech-switch>
          </div>
        </div>

        <div class="mt-3 mb-[8px] flex items-center justify-between">
          <div>
            <span class="text-sm sch-input-label">Image Upload </span>
          </div>
          <!-- <div class="mx-2">
            <button
              sch-button
              id="trigger-upload"
              (click)="fileUpload.triggerUpload()"
              class="!py-[2px] h-fit !px-6 !text-[0.9rem] upload-photo !capitalize"
            >
              Upload
            </button>

          </div> -->
        </div>

        <div class="image-list mb-4">
          <div class="image-list-add">
            <i
              (click)="fileUpload.triggerUpload()"
              [sch-icon]="'add-circle'"
              class=""
            ></i>

            <seech-file-upload
              id="file-upload"
              [maxSizeInMb]="5"
              #fileUpload="seechFileUpload"
              [size]="'md'"
              [disabled]="false"
              [acceptMultiple]="true"
              [max]="10"
              [fileTypes]="fileTypes"
              (selected)="onFileSelected($event)"
            >
              Upload a file
            </seech-file-upload>
          </div>

          <ng-container *ngIf="tempImages.length >= 1">
            <div
              class="image-list-inner"
              *ngFor="let item of tempImages; let i = index"
            >
              <img
                [src]="item"
                class="image-list-inner-image"
                alt="Uploaded Image"
              />

              <i
                [sch-icon]="'close'"
                class="image-list-inner-icon"
                (click)="removeAddedImage(i)"
              ></i>
            </div>
          </ng-container>
        </div>

        <!-- <seech-file-upload
          class="hidden"
          id="hello"
          #fileUpload
          formControlName="image"
          [maxSizeInMb]="1"
          [fileTypes]="['image/*']"
          (selected)="onFileSelected($event)"
          >Upload file</seech-file-upload
        >
        <ng-container>
          <div class="flex w-full">
            <div class="dotted-card w-16 h-16 border-dotted-2 border-secondary flex items-center">
              <i
                (click)="fileUpload.triggerUpload()"
                [sch-icon]="'add-circle'"
                class=""
              ></i>
            </div>
            <div
              class="image-list-inner"
              *ngFor="let image of tempImages; let i = index"
            >
              <img
                [src]="image"
                class="image-list-inner-image"
                alt="Uploaded Image"
              />

              <i
                [sch-icon]="'close'"
                class="image-list-inner-icon"
                (click)="removeAddedImage(i)"
              ></i>
            </div>
          </div>
        </ng-container> -->

        <!-- <div class="images-preview flex items-start justify-start my-3">
          <div
            class="image-previews !object-cover !rounded-md !overflow-hidden !mr-2 !relative"
            *ngFor="let image of tempImages; let i = index"
          >
            <img
              class="image-preview !object-cover rounded-xl"
              [src]="image"
              alt="image"
            />
          </div>
        </div> -->

        <div class="submit w-full">
          <button
            sch-button
            class="w-full !text-[15px] submit-btn"
            type="submit"
            (click)="onSubmit()"
          >
            {{ submitBtnText }}
          </button>
        </div>
      </form>

      <div *ngIf="isSubmitting" class="w-full">
        <seech-progress-bar
          [id]="'progress-3'"
          [progress]="30"
          [isIndeterminate]="true"
          [color]="'success'"
        ></seech-progress-bar>
      </div>
    </div>

    <ng-container *ngIf="formSubmitted">
      <section class="flex items-center justify-center py-5">
        <div class="message text-center">
          <h1 class="text-[20px] font-semibold success-h1">Awesome</h1>
          <p class="text-[16px] text-[#626262] font-[400] my-4">
            {{ responseText }}
          </p>
          <button
            sch-button
            class="w-full !text-[15px] submit-btn"
            type="submit"
            (click)="onClosed()"
          >
            Close
          </button>
        </div>
      </section>
    </ng-container>
  </app-general-modal>
</section>
