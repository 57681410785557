import { Directive, ElementRef, Input } from '@angular/core';
import { CONSTANT, User } from '@profindar/shared-ng';

@Directive({
  selector: '[appUserImage]',
})
export class UserImageDirective {
  private user = {} as User;
  private get element(): HTMLElement {
    return this.el.nativeElement as HTMLElement;
  }

  @Input() public styleDeclaration = <CSSStyleDeclaration>{};

  @Input() set appUserImage(user: User) {
    this.initialize(user);
  }

  constructor(private el: ElementRef) {}

  initialize(user: User) {
    this.user = user;
    const imageUrl = user?.imageURL;

    if (!imageUrl) {
      this.element.style.backgroundImage = ``;
      this.element.style.backgroundColor = this.styleDeclaration.backgroundColor || CONSTANT.COLOR.SECONDARY;
      this.element.textContent = `${this.formattedName(user)}`;
      this.element.style.textAlign = this.styleDeclaration.textAlign || 'center';
      this.element.style.display = this.styleDeclaration.display || 'flex';
      this.element.style.justifyContent = this.styleDeclaration.justifyContent || 'center';
      this.element.style.alignItems = this.styleDeclaration.alignItems || 'center';
      this.element.style.color = this.styleDeclaration.color || 'white';
      this.element.style.borderRadius = this.styleDeclaration.borderRadius || '50%';
    } else {
      this.element.textContent = ``;
      // this.userImage = this.userService.getUserImageString(imageUrl);
      // this.element.style.backgroundImage = `${this.userImage}`;
      this.element.style.backgroundImage = imageUrl;
    }
  }

  formattedName(user: User): string {
    if (!user) {
      return '';
    } else if (user.name) {
      const userNames = user.name.split(' ');
      const names = this.searchAndFormatName(userNames);
      const fn = names[0][0];
      const ln = names[1][0];
      return (fn + ln).toLocaleUpperCase();
    }

    return user?.username ? user.username.split(' ')[0][0].toLocaleUpperCase() : '';
  }

  searchAndFormatName(names: string[]): string[] {
    const userNames = [];
    for (let i = 0; i < names.length; i++) {
      if (names[i] != '') {
        userNames.push(names[i].trim());
      }
    }
    return userNames;
  }
}
