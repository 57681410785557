import { Requisition, RequisitionResponse } from "@profindar/shared-ng";

export * from "./project.model";
export * from "./service.model";

//This is temporary
export function toRequisitionResponse(requisition: Requisition):RequisitionResponse {
  const tasks = requisition.requestedTasks.map((task) => task.description);
  const payRateType = requisition.payRateType?.description;
  const services = requisition.requestedServices.map(
    (service) => service?.service?.description
  );

  return {
    id: requisition.id,
    budget: requisition.budget,
    createdBy: requisition.createdBy,
    createdOn: requisition.createdOn,
    proposedStartDate: requisition.proposedStartDate,
    proposedEndDate: requisition.proposedEndDate,
    expirationDate: requisition.expirationDate,
    professionalLocation: requisition.professionalLocation,
    clientLocation: requisition.clientLocation,
    isRemote: requisition.isRemote,
    isMobile: requisition.isMobile,
    currency: requisition.currency,
    location: requisition.location,
    description: requisition.description,
    hasBid: requisition.hasBid,
    isSaved: requisition.isSaved,
    payRateType: payRateType,
    status: requisition.statusId,
    statusId: requisition.statusId,
    services: services,
    tasks: tasks,
    coordinates:requisition.coordinates,
    mediaCount: requisition.mediaCount
  };
}
