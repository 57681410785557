/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-const */
/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Injectable }                      from '@angular/core';
import { HttpClient, HttpParams        }       from '@angular/common/http';
import { map, Observable }                                        from 'rxjs';

// @ts-ignore
import { BlobResponse } from '../model/blobResponse';
import { StreamingService } from '@seech/core-ng';
import { Media } from '@seech/media-ng';

@Injectable({
  providedIn: 'root'
})
export class FileService {

    constructor(private http: HttpClient, private streamingService: StreamingService,) {}

    stageUpload(file?: Array<File>): Observable<Array<BlobResponse>> {
        // Create FormData and append files
        const formData = new FormData();
        file?.forEach((element) => formData.append('file', element));
    
        // Return the observable from the POST request
        return this.http.post<Array<BlobResponse>>('/api/Storage/stage', formData)
    }

    upload(file?: Array<File>, containerId?: string, containerName?: string): Observable<Array<BlobResponse>> {
      // Create FormData and append files
      const formData = new FormData();
      file?.forEach((element) => formData.append('files', element));
      // Set query parameters
      const params = new HttpParams().set('containerId', containerId as string)
                                     .set('containerName', containerName as string);
  
      // Return the observable from the POST request with query params
      return this.http.post<Array<BlobResponse>>('/api/Storage/upload', formData, { params });
    }

    getUserImageUrl(imageUrl: string, callback: (url: string) => void): void {
      if (imageUrl) {
        this.streamingService.stream(imageUrl).subscribe({
          next: (blob) => {
            const url = URL.createObjectURL(blob as Blob);
            callback(url); // Pass the URL to the callback
          },
          error: (error) => {
            console.error('Error loading image:', error);
            callback(''); // Pass an empty string if there is an error
          }
        });
      } else {
        callback(''); // Return an empty string if imageUrl is empty
      }
    }

    createMediaItem(item: any): Media {
      return {
        id: item.id as string,
        src: item.url, 
        type: this.getBlobType(item.type), 
        alt: 'gallery-image',
      };
    }
    
    // Helper method to transform blobType to 'video' | 'image' | undefined
    getBlobType(blobType: string): 'video' | 'image' | undefined {
      if (blobType === 'video') {
        return 'video';
      } else if (blobType === 'image') {
        return 'image';
      } else {
        return undefined; // If blobType is neither 'video' nor 'image'
      }
    }

    // Helper function to handle blob URL creation
    getMediaBlobUrl(blobType: string, url: string, blob: Blob): string {
      return blobType === "video" ? url : URL.createObjectURL(blob);
    }
  
}
