
export const ROUTE = {
  HOME: 'home',
  ROOT: '',
  DASHBOARD: 'dashboard',
  USER: 'user',
  MESSAGE: 'message',
  SEARCH: 'search',
  PROJECT: 'project',
  NOTIFICATION: 'notification',
  INVITATION: 'invitation',
  PROFILE: 'profile',
  SETTINGS: 'settings',
  CALENDAR: 'calendar',
  SAVED: 'saved',
  HELP: 'help',
  BIDS: 'user/bids',
  BOOKMARKS: 'user/bookmarks',
  JOB_INVITATIONS: 'user/job-invitations',
  PRO: 'professional',
  PRODUCTS: 'products',
  ASSOCIATES: 'associates',
  JOB: 'job',
  PAYMENTS: 'payments',
  REQUISITION: 'requisition',
  MY_BIDS: 'user/bids',
  REQ: 'requisition/list',
  REQ_DETAIL: 'requisition/detail',
  ASSOCIATES_DETAIL: 'associates/detail',
  PROJECTS: 'project/list',
  PROJECT_DETAIL: 'project/detail',
  SERVICES: 'services',

  PROFILE_ROUTES: {
    SERVICES: 'services',
    PORTFOLIO: 'portfolio',
    GALLERY: 'gallery',
  },

  REQUISITION_DETAIL_ROUTES: {
    SUMMARY: 'summary',
    TASKS: 'tasks',
    GALLERY: 'gallery',
    BIDS: 'bids',
  },

  PROJECT_DETAIL_ROUTES: {
    SUMMARY: 'summary',
    TASKS: 'tasks',
    GALLERY: 'gallery',
    CONVERSATIONS: 'conversations',
  },
  
  CALENDAR_ROUTES: {
    WEEK: 'week',
    DAY: 'day',
  },
};