import { Injectable } from '@angular/core';
import {
  InviteProfessionalsRequest,
  InvitedProfessionalRequest,
  RequisitionService as RequisitionClient,
  UpdateRequisitionRequest,
} from '../../../../generated';
import { RequisitionRequest } from '../../../../models';

@Injectable({
  providedIn: 'root',
})
export class RequisitionService {
  constructor(private requisitionClient: RequisitionClient) { }

  /**
   * Creates a new requisition.
   * @param {Requisition} requisition - The requisition to be created.
   * @returns {Observable<any>} An observable of the created requisition.
   */
  create(requisition: RequisitionRequest) {
    return this.requisitionClient.requisitionCreate(requisition);
  }

  /**
   * Creates a new requisition.
   * @param {UpdateRequisitionRequest} requisition - The requisition to be created.
   * @returns {Observable<any>} An observable of the created requisition.
   */
  update(requisition: UpdateRequisitionRequest) {
    return this.requisitionClient.requisitionUpdateRequisition(requisition);
  }

  /**
   * Retrieves requisitions based on provided parameters.
   * @param {string | undefined} query - The query string for filtering requisitions.
   * @param {number | undefined} page - The page number for paginated results.
   * @param {number | undefined} size - The size of each page in paginated results.
   * @param {string | undefined} status - The status of requisitions to filter by.
   * @returns {Observable<any>} An observable of requisitions based on the provided parameters.
   */
  getCurrentUserRequisitions(
    query?: string,
    page?: number,
    size?: number,
    requisitionStatus?: string
  ) {
    return this.requisitionClient.requisitionGetCurrentUserRequisitions(
      query,
      page,
      size,
      requisitionStatus
    );
  }

  /**
   * Retrieves user-specific requisitions based on provided parameters.
   * @param {string | undefined} status - The status of requisitions to filter by.
   * @param {string | undefined} query - The query string for filtering requisitions.
   * @param {number | undefined} page - The page number for paginated results.
   * @param {number | undefined} size - The size of each page in paginated results.
   * @returns {Observable<any>} An observable of user-specific requisitions based on the provided parameters.
   */
  getRequisitionsWithInvitation(page?: number, size?: number, query?: string) {
    return this.requisitionClient.requisitionGetRequisitionsWithInvitation(
      page,
      size,
      query
    );
  }

  /**
   * Uninvites a professional from a requisition.
   * @param {string} requisitionId 
   * @param {InvitedProfessionalRequest} updatedInvitations - The ID of the requisition from which to uninvite the professional.
   * @returns {Observable<any>} An observable indicating the success of the operation.
   */
  inviteProfessionals(requisitionId: string, updatedInvitations: InvitedProfessionalRequest[]) {
    return this.requisitionClient.requisitionUpdateBidInvitation(requisitionId, updatedInvitations)
  }

  /**
 * invites a professional from a requisition.
 * @param {string}  - The ID of the requisition from which to uninvite the professional.
 * @returns {Observable<any>} An observable indicating the success of the operation.
 */
  unInviteProfessional(requisitionId: string) {
    return this.requisitionClient.requisitionUnInviteProfessional(
      requisitionId
    );
  }

  /**
   * Retrieves requisition count by status.
   * @returns {Observable<StatusSummaryResponse>} An observable that resolves to count of projects by status.
   */
  getRequisitionCountByStatus() {
    return this.requisitionClient.requisitionGetRequisitionCountByStatus();
  }

  /**
   * Retrieves requisitions created by based on provided parameters.
   * @param {string | undefined} query - The query string for filtering requisitions.
   * @param {number | undefined} page - The page number for paginated results.
   * @param {number | undefined} size - The size of each page in paginated results.
   * @returns {Observable<any>} An observable of requisitions based on the provided parameters.
   */
  getRequisitionsCreatedByUser(query?: string, page?: number, size?: number) {
    return this.requisitionClient.requisitionGetRequisitionsCreatedByUser(
      query,
      page,
      size
    );
  }

  //     public requisitionGetRequisitionDetailsById(id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<RequisitionOfRequisitionInterface>;

  /**
   * Retrieves details of a requisition by ID.
   * @param {string} id - The ID of the requisition.
   * @returns {Observable<any>} An observable of details of the requisition.
   */

  getRequisitionDetailsById(id: string) {
    return this.requisitionClient.requisitionGetRequisitionDetailsById(id);
  }

  /**
   * Retrieves professionals suggested for a requisition being created.
   * @param {string} query - The query string for filtering recommended professionals.
   * @param {number} page - The page number for paginated results.
   * @param {number} size - The size of each page in paginated results.
   * @returns {Observable<ProfessionalSummary>} An observable of professionals based on the provided parameters.
   */

  getRequisitionProfessionalRecommendations(query: string, page: number, size: number) {
    return this.requisitionClient.requisitionProfessionalRecommendations(query, page, size);
  }

  // reposr 
  /**
   * Duplicate a requisition and get the new requisition id
   * @param {string} requisitionId - The ID of the requisition to duplicate.
   * @returns {Observable<any>} An observable of the new requisition id.
   */

  repostRequisition(requisitionId: string) {
    return this.requisitionClient.requisitionRepostRequisition(requisitionId);
  }
}
