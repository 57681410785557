import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RequisitionDetailComponent } from './components/requisition-detail/requisition-detail.component';
import { ItemsModule } from '../items/items.module';
import { IconsNgModule } from '@seech/icons-ng';
import { ReviewModule } from '../review/review.module';
import { ProfessionalSummaryDetailComponent } from './components/professional-summary-detail/professional-summary-detail.component';
import { ServiceDetailComponent } from './components/service-detail/service-detail.component';
import { ComponentsModule } from '../components/components.module';
import { SharedModule } from '../../shared/shared.module';
import { ServiceDetailDialogComponent } from './dialogs/service-detail-dialog/service-detail-dialog.component';
import { ContentToggleDirective, UxNgModule } from '@seech/ux-ng';
import { InitialsPipe, SplitStringPipe } from '@profindar/shared-ng';
import { HireModalComponent } from './components/professional-summary-detail/hire-modal/hire-modal.component';
import { TaskSummaryComponent } from './components/task-summary/task-summary.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    RequisitionDetailComponent,
    ProfessionalSummaryDetailComponent,
    ServiceDetailComponent,
    RequisitionDetailComponent,
    // TaskSummaryComponent,
    ServiceDetailDialogComponent,
    HireModalComponent,
  ],
  imports: [
    CommonModule,
    ItemsModule,
    FormsModule,
    IconsNgModule,
    ReviewModule,
    ComponentsModule,
    SharedModule,
    NgOptimizedImage,
    UxNgModule,
    InitialsPipe,
    SplitStringPipe,
    TaskSummaryComponent,
    RouterModule,
    ContentToggleDirective
  ],
  exports: [
    RequisitionDetailComponent,
    ProfessionalSummaryDetailComponent,
    ServiceDetailComponent,
    RequisitionDetailComponent,
    // TaskSummaryComponent
  ],
})
export class DetailModule { }
