import { ResolveFn, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable, Injector } from '@angular/core';
import { AuthService } from '@seech/auth-ng';
import { Session, Token, User } from '@profindar/shared-ng';

@Injectable({
  providedIn: 'root'
})
export class AppResolver {
  static resolve: ResolveFn<boolean> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const injector = Injector.create({
      providers: [
        { provide: AuthService<User, Session, Token>, deps: [] },
        { provide: Router, deps: [] }
      ]
    });
    
    const authService = injector.get(AuthService);
    const router = injector.get(Router);

    if (authService.isLoggedInNotExpired()) {
      router.navigate(['/home']);
      return true; // Assuming you want to return a resolved value
    } else {
      authService.gotoExternalLogin();
      return false; // Assuming you want to return a resolved value
    }
  }
}
