import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

export interface LayoutData {
  showSideNav: boolean;
  showFooter:boolean
}
@Injectable({
  providedIn: 'root',
})
export class LayoutService {

  defaultLayoutConfig: LayoutData = {
    showSideNav: true,
    showFooter: true,
  };

  private _layoutData = new ReplaySubject<LayoutData>();

  public get layoutData(): Observable<LayoutData> {
    return this._layoutData.asObservable();
  }

  public set layoutData(value: LayoutData) {
    this._layoutData.next(value);
  }

  constructor() {}

  useDefaultConfig() {
    this.layoutData = this.defaultLayoutConfig;
  }
}
