  <seech-carousel
      #carousel
      animation="slide"
      [mediaItems]="mediaItems"
      [showFullVisibleMedia]="false"
      [controls]="controls"
      [indicators]="indicators"
      [activeMedia]="activeMedia"
      id="gallery-carousel"
></seech-carousel>
