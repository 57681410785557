<header class="h-100">
  <div class="d-flex align-items-center  justify-content-between mobile-header">
    <div class="logo">
      <a [routerLink]="[route.HOME]">
        <span class="pro">Pro</span><span class="text-white">Findar</span>
      </a>
    </div>


    <div class="d-flex gap-3">

      <a [routerLink]="route.MESSAGE" class="circle">
        <i sch-icon="mail" [sch-badge]="unreadMessagesCount > 0"></i>
      </a>

      <a [routerLink]="route.NOTIFICATION" class="circle">
        <i sch-icon="notification" [sch-badge]="unreadNotificationCount>0"></i>
      </a>

      <div (click)="profileClick.emit()">
        <img *ngIf="user?.imageURL else nameLabelTemplate" [src]="user?.imageURL" width="30" height="auto"
        class="mobile-name-label d-flex justify-content-center align-items-center">

        <ng-template #nameLabelTemplate>
          <div class="mobile-name-label d-flex justify-content-center align-items-center">
            <span class="text-white">{{nameLabel}}</span>
          </div>
        </ng-template>
      </div>

    </div>
  </div>

  <seech-progress-bar *ngIf="isLoading" [isIndeterminate]="true" [color]="'secondary'"></seech-progress-bar>
</header>
