/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EntityTag } from './entityTag';


export interface Tag { 
    show: boolean;
    id: string;
    name: string;
    createdOn: Date;
    colorCode?: string;
    isVerified?: boolean;
    parentId?: string;
    description?: string;
    entity_Tags: Array<EntityTag>;
    inverseParent: Array<Tag>;
    parent?: Tag;
}

