/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { Badge } from '../model/models';
import { CountryPhoneCode } from '../model/models';
import { Currency } from '../model/models';
import { Intent } from '../model/models';
import { Lookup } from '../model/models';
import { ProjectTaskStatus } from '../model/models';
import { ReviewRating } from '../model/models';
import { Service } from '../model/models';
import { ServiceRequest } from '../model/models';
import { Skill } from '../model/models';
import { SkillRequest } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface LookupServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param serviceRequest 
     */
    lookupAddService(serviceRequest: ServiceRequest, extraHttpRequestParams?: any): Observable<Service>;

    /**
     * 
     * 
     * @param skillRequest 
     */
    lookupAddSkill(skillRequest: SkillRequest, extraHttpRequestParams?: any): Observable<Skill>;

    /**
     * 
     * 
     * @param roleId 
     * @param keyword 
     */
    lookupGetBadges(roleId: string, keyword?: string, extraHttpRequestParams?: any): Observable<Array<Badge>>;

    /**
     * 
     * 
     * @param keyword 
     */
    lookupGetBidStatus(keyword?: string, extraHttpRequestParams?: any): Observable<Array<Lookup>>;

    /**
     * 
     * 
     * @param keyword 
     * @param page 
     * @param size 
     * @param requiredItems 
     */
    lookupGetCountriesWithPhoneCodes(keyword?: string, page?: number, size?: number, requiredItems?: string, extraHttpRequestParams?: any): Observable<Array<CountryPhoneCode>>;

    /**
     * 
     * 
     * @param keyword 
     * @param page 
     * @param size 
     * @param requiredItems 
     */
    lookupGetCurrencies(keyword?: string, page?: number, size?: number, requiredItems?: string, extraHttpRequestParams?: any): Observable<Array<Currency>>;

    /**
     * 
     * 
     * @param currencyCode 
     */
    lookupGetCurrencyByCurrencyCode(currencyCode: string, extraHttpRequestParams?: any): Observable<Currency>;

    /**
     * 
     * 
     * @param countryCode 
     * @param currencyCode 
     */
    lookupGetCurrencyByParams(countryCode?: string, currencyCode?: string, extraHttpRequestParams?: any): Observable<Currency>;

    /**
     * 
     * 
     */
    lookupGetIntents(extraHttpRequestParams?: any): Observable<Array<Intent>>;

    /**
     * 
     * 
     * @param keyword 
     */
    lookupGetPayRateTypes(keyword?: string, extraHttpRequestParams?: any): Observable<Array<Lookup>>;

    /**
     * 
     * 
     */
    lookupGetPaymentStatuses(extraHttpRequestParams?: any): Observable<Array<Lookup>>;

    /**
     * 
     * 
     * @param keyword 
     */
    lookupGetProjectStatus(keyword?: string, extraHttpRequestParams?: any): Observable<Array<Lookup>>;

    /**
     * 
     * 
     * @param keyword 
     */
    lookupGetRequisitionStatus(keyword?: string, extraHttpRequestParams?: any): Observable<Array<Lookup>>;

    /**
     * 
     * 
     * @param keyword 
     */
    lookupGetRoles(keyword?: string, extraHttpRequestParams?: any): Observable<Array<Lookup>>;

    /**
     * 
     * 
     * @param serviceId 
     * @param query 
     * @param page 
     * @param size 
     */
    lookupGetSkillsByServiceId(serviceId: string, query?: string, page?: number, size?: number, extraHttpRequestParams?: any): Observable<Array<Skill>>;

    /**
     * 
     * 
     * @param query 
     * @param page 
     * @param size 
     */
    lookupRatings(query?: string, page?: number, size?: number, extraHttpRequestParams?: any): Observable<Array<ReviewRating>>;

    /**
     * 
     * 
     * @param query 
     * @param page 
     * @param size 
     */
    lookupServices(query?: string, page?: number, size?: number, extraHttpRequestParams?: any): Observable<Array<Service>>;

    /**
     * 
     * 
     * @param query 
     * @param page 
     * @param size 
     */
    lookupSkills(query?: string, page?: number, size?: number, extraHttpRequestParams?: any): Observable<Array<Skill>>;

    /**
     * 
     * 
     * @param query 
     * @param page 
     * @param size 
     */
    lookupTaskStatus(query?: string, page?: number, size?: number, extraHttpRequestParams?: any): Observable<Array<ProjectTaskStatus>>;

}
