<header class="h-100">
  <div class="row header h-100">
    <div class="col-4">

      <div class="d-flex align-items-center align-content-center gap-3">
        <button *ngIf="showExplore" (click)="exploreClick.emit()" class="hamburger-menu">
          <div class="text-center">
            <i [sch-icon]="'menu'">
            </i>
          </div>
        </button>

        <a [routerLink]="[route.HOME]">
          <div class="logo">
            <span class="pro">Pro</span><span class="text-white">Findar</span>
          </div>
        </a>
      </div>

    </div>

    <div class="col-4">
      <div class="search-container">
        <input type="text" class="no-border-input" placeholder="Search">
        <i [sch-icon]="'search'"></i>
      </div>
    </div>

    <div class="col-4">
      <div class="d-flex align-items-center justify-content-end header-icons">

        <a [routerLink]="route.NOTIFICATION">
          <div class="text-center">
            <i [sch-icon]="'notification'" [sch-badge]="unreadNotificationCount > 0">
            </i>
            <p>Notifications</p>
          </div>
        </a>
        <a [routerLink]="route.MESSAGE">
          <div class="text-center">
            <i [sch-icon]="'mail'" [sch-badge]="unreadMessagesCount > 0">
            </i>
            <p>Messages</p>
          </div>
        </a>

        <div class="header-vr"></div>

        <div class="d-flex h-100 cursor-pointer" (click)="profileClick.emit()">
          <img *ngIf="user?.imageURL else nameLabelTemplate" [src]="user?.imageURL" width="40" height="auto"
          class="name-label d-flex justify-content-center align-items-center">
          <ng-template #nameLabelTemplate>
            <div class="name-label d-flex justify-content-center align-items-center">
              <span class="text-white">{{nameLabel}}</span>
            </div>
          </ng-template>
        </div>

      </div>
    </div>
  </div>
  <seech-progress-bar *ngIf="isLoading" [isIndeterminate]="true" [color]="'secondary'"></seech-progress-bar>
</header>
