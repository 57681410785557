/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Requirement } from './requirement';
import { Badge } from './badge';


export interface ProjectRole { 
    requirements: Array<Requirement>;
    id: string;
    name: string;
    description: string;
    badges: Array<Badge>;
}

