import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  Chip,
  ChipConfig,
  ChipOverride,
  ModalService,
  ToastService,
  UxNgModule,
} from '@seech/ux-ng';
import { ComponentsModule } from '../components/components.module';
import { IconsNgModule } from '@seech/icons-ng';
import { TaskSummaryComponent } from '../detail/components/task-summary/task-summary.component';
import { FormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import {
  ControlsNgModule,
  Currency,
  ValidationService,
} from '@seech/controls-ng';
import { BidFormComponent } from './bid-form/bid-form.component';
import {
  Bid,
  BidRequest,
  BidResponse,
  BidResponseRequest,
  BidService,
  LookupService,
  RequisitionResponse,
} from '@profindar/shared-ng';
import { SessionService } from '../../../services/session.service';
import { Guid } from 'typescript-guid';

@Component({
  selector: 'app-bid',
  standalone: true,
  imports: [
    CommonModule,
    ControlsNgModule,
    BidFormComponent,
    UxNgModule,
    ComponentsModule,
    IconsNgModule,
    TaskSummaryComponent,
    FormsModule,
    OverlayModule,
  ],
  templateUrl: './bid.component.html',
  styleUrls: ['./bid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BidComponent implements OnInit {
  requisition!: RequisitionResponse;
  bidResponse?: BidResponse;
  clientBid!: Bid;
  professionalBid!: Bid;
  professionalId?: string;
  professionalName = '';
  userIsClient = false;
  currency!: Currency;
  payRateType = '';
  tasks: string[] = [];
  showMoreFields = false;
  bidAcceptDrop = false;
  bidSubmitDrop = false;
  requisitionId?: string;
  @Input() showDialogTitle = true;

  services: Chip[] = [];
  overrides: ChipOverride = {
    exclude: ['secondary'],
    replacement: 'info',
  };
  config: ChipConfig = {
    override: this.overrides,
  };

  constructor(
    public modalService: ModalService,
    public bidService: BidService,
    public cdRef: ChangeDetectorRef,
    public toastService: ToastService,
    private sessionService: SessionService,
    private validationService: ValidationService,
    private lookupService: LookupService
  ) {}

  ngOnInit(): void {
    this.modalService.modalRefComponent().subscribe({
      next: (data) => {
        const user = this.sessionService.getCurrentUser()!;
        this.requisitionId = data.requisitionId;
        this.professionalId =
          data.bidSessionId ?? this.sessionService.getCurrentUserId();

        this.bidService
          .getBidSessionForRequisition(
            this.requisitionId!,
            this.professionalId!
          )
          .subscribe({
            next: (data) => {
              this.clientBid = data.clientBid;
              this.professionalBid = data.professionalBid;
              this.requisition = data.requisitionResponse;
              this.professionalName = data.professionalUserInfo.name;
              this.bidResponse = data;
              this.tasks = this.requisition.tasks;
              this.payRateType = this.requisition.payRateType!;
              this.userIsClient = this.requisition.createdBy === user.id;
              this.services = this.requisition.services.map(
                (requestedService) => {
                  return {
                    description: requestedService,
                    value: requestedService,
                    color: 'primary',
                  };
                }
              );
              const currencyCode = this.requisition.currency!;
              this.lookupService
                .getCurrencyByCurrencyCode(currencyCode)
                .subscribe({
                  next: (currency) => {
                    this.currency = {
                      countryCode: currency.countryCode
                        ? currency.countryCode
                        : '',
                      currencyCode: currency.currencyCode,
                      currencyName: currency.currencyName,
                      currencySymbol: currency.currencySymbol,
                    };
                  },
                  complete: () => {
                    this.cdRef.detectChanges();
                  },
                });
            },
          });
      },
    });
  }

  jobTermsChanged(
    event: Event,
    professionalBidForm: BidFormComponent,
    clientBidForm: BidFormComponent
  ) {
    const isChecked = (event.target as HTMLInputElement).checked;
    const bid = isChecked ? this.clientBid : this.professionalBid;
    professionalBidForm.updateForm(bid);
    clientBidForm.resetMessageFieldHeightToDefault();
  }

  noProfessionalBid() {
    return this.professionalBid?.id === Guid.EMPTY.toString();
  }

  getDateTime(dateString: string): Date {
    const formattedDate = this.validationService.reformatDate(
      dateString,
      'mm/dd/yyyy'
    );
    const [month, day, year] = formattedDate.split('/').map(Number);
    const now = new Date();
    const dateTime = new Date(
      year,
      month - 1,
      day,
      now.getHours(),
      now.getMinutes(),
      now.getSeconds(),
      now.getMilliseconds()
    );
    return dateTime;
  }

  submitBid(bidForm: BidFormComponent, e: Event) {
    this.submit(bidForm, e);
  }

  counterBid(bidForm: BidFormComponent, e: Event) {
    this.submit(bidForm, e);
  }

  withdrawBid(id: string) {
    this.bidService.withdrawBid(id).subscribe({
      next: (bid) => {
        this.toastService.success('Bid withdrawn successfully');
        this.closeDialog({
          requisitionId: this.requisitionId,
          hasBid: false,
        });
      },
      error: () => {
        this.toastService.error(
          'Unable to withdraw bid at the moment. Please try again later.'
        );
      },
    });
  }

  noClientBid() {
    return this.clientBid?.id === Guid.EMPTY.toString();
  }

  private submit(bidForm: BidFormComponent, e: Event) {
    const button = e.target as HTMLButtonElement;
    button.disabled = true;

    if (bidForm.form.invalid) {
      bidForm.validateForm();
      button.disabled = false;
    }
    if (
      bidForm.form.errors?.['endDateInvalid'] ||
      bidForm.form.errors?.['expiryDateInvalid']
    ) {
      this.showMoreFields = true;
    }

    if (bidForm.form.valid) {
      const formValue = bidForm.form.value;
      const stringAmount = bidForm.form.value.amount;
      const startDate = this.getDateTime(formValue.startDate);
      const endDate = formValue.endDate
        ? this.getDateTime(formValue.endDate)
        : undefined;
      const expiresOn = formValue.expiresOn
        ? this.getDateTime(formValue.expiresOn)
        : undefined;

      const bidRequest: BidRequest = {
        ...formValue,
        startDate: startDate,
        endDate: endDate,
        expiresOn: expiresOn,
        requisitionId: this.requisition.id,
        amount: parseInt(stringAmount.replace(/[^0-9.]/g, '')),
        professionalId: this.professionalId,
        currency: this.requisition.currency,
      };

      this.bidService.bid(bidRequest).subscribe({
        next: (bid) => {
          this.toastService.success(
            `Bid ${this.userIsClient ? 'countered' : 'created'} successfully`
          );
          this.closeDialog({
            requisitionId: this.requisitionId,
            hasBid: true,
          });
        },
        error: (error) => {
          this.toastService.error(
            'Bid creation failed. Please try again later.'
          );
        },
        complete: () => {
          button.disabled = false;
        },
      });
    }
    this.cdRef.detectChanges();
  }

  declineBid(bid: Bid) {
    const bidResponseRequest: BidResponseRequest = {
      professionalId: bid.professionalId,
      requisitionId: bid.requisitionId,
      bidStatus: 'declined',
    };
    this.bidService.bidResponse(bidResponseRequest).subscribe({
      next: (bid) => {
        this.toastService.success('Bid was declined successfully');
        this.closeDialog();
      },
      error: (error) => {
        this.toastService.error('Bid decline failed. Please try again later.');
      },
    });
  }

  acceptBid(bid: Bid, e: Event) {
    const button = e.target as HTMLButtonElement;
    button.disabled = true;
    const bidResponseRequest: BidResponseRequest = {
      professionalId: bid.professionalId,
      requisitionId: bid.requisitionId,
      bidStatus: 'accepted',
    };
    this.bidService.bidResponse(bidResponseRequest).subscribe({
      next: (bid) => {
        this.toastService.success('Bid was accepted successfully');
        this.closeDialog();
      },
      error: (error) => {
        this.toastService.error(
          'Unable to accept bid. Please try again later.'
        );
      },
      complete: () => {
        button.disabled = false;
      },
    });
  }

  closeDialog(data?: any) {
    this.modalService.close(null, data);
  }
}
