<ng-container *ngIf="!conversation && !loadingRecipients && !startingNewThread; else content">
    <div class="empty-state">
        <img src="{{cdnBaseUrl}}images/messages-empty-state.svg" alt="empty message image">
    
        <p class="text">
            You do not have any conversation at this time.
        </p>
    </div>
</ng-container>

<ng-template #content>
    <div class="message-layout" [ngClass]="{'open-profile': profileOpen || standAlone, 'bg-grey': !standAlone}">
        <div class="header" [class.stand-alone]="standAlone">
            <div class="d-flex flex-row align-items-center flex-grow-1 overflow-hidden gap-2">
                <div class="action d-md-none" [class.d-none]="standAlone" (click)="goBack.emit()">
                    <i [sch-icon]="'prev-arrow'" class="text-secondary"></i>
                </div>
                <ng-container *ngIf="(conversation && !loadingRecipients) || startingNewThread; else loadingState">
                    <span class="profile-pic" [styleDeclaration]="styleDeclaration" [appUserImageCollage]="conversation?.user" (click)="openProfile.emit(!profileOpen)"></span>
                    <div class="header-info">
                        <div class="top" [conversation]="conversation || null" appMessageCheckStringOverflow>
                            <p class="name" (click)="openProfile.emit(!profileOpen)">
                                {{conversation?.user | concatenateUserNames:userProfile.id}}
                            </p>
                            <div class="more" (click)="showParticipants = !showParticipants" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
                                <i [sch-icon]="'more-horizontal-fill'"></i>
                            </div>
                        </div>
                        <div class="status" (click)="participantCount < 2 ? null : showParticipants = !showParticipants" 
                        cdkOverlayOrigin #trigger="cdkOverlayOrigin">
                            <div class="indicator" *ngIf="participantCount < 2"></div>
                            <p class="time">{{participantCount < 2 ? 'Active Now' : (participantCount + 1) + ' Participants'}}</p>
                        </div>
    
                        <ng-template cdkConnectedOverlay cdkConnectedOverlayHasBackdrop="true"
                        cdkConnectedOverlayBackdropClass="bg-transparent" 
                        (overlayOutsideClick)="showParticipants = false"
                        [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOffsetY]="10"
                        [cdkConnectedOverlayOpen]="showParticipants && participantCount > 1">
                            <app-participant-list [particpants]="conversation?.user!" 
                            (particpantClick)="showParticipants = false; particpantClick.emit($event)"></app-participant-list>
                        </ng-template>
                    </div>
                </ng-container>

                <ng-template #loadingState>
                    <span class="profile-pic">
                        <seech-placeholder [width]="'100%'" [height]="'100%'" [shape]="'circle'"></seech-placeholder>
                    </span>

                    <div class="header-info">
                        <div class="top">
                            <p class="name">
                                <seech-placeholder [width]="'60%'" [height]="'14px'"></seech-placeholder>
                            </p>
                        </div>
                        <div class="status">
                            <p class="time">
                                <seech-placeholder [width]="'80px'" [height]="'10px'"></seech-placeholder>
                            </p>
                        </div>
                    </div>
                </ng-template>
            </div>

    
            <div class="d-flex flex-row align-items-center gap-2">
                <div class="action">
                    <i [sch-icon]="'phone'" class="text-secondary"></i>
                </div>
                <div class="action">
                    <i [sch-icon]="'video-camera'" class="text-secondary"></i>
                </div>
                <div class="action" (click)="openProfile.emit(!profileOpen)">
                    <i [sch-icon]="profileOpen ? 'fullscreen-exit' : 'fullscreen'"></i>
                </div>
            </div>
        </div>

        <div class="message-box">
            <div class="message-box-content">
                <ng-container *ngIf="!conversations || conversations.length === 0; else messageArea">
                    <ng-container *ngIf="!conversations; else emptyState">
                        <div class="message-box-content-area overflow-hidden">
                            <ng-container *ngFor="let item of [0, 1, 2, 3, 4, 5]; let even = even">
                                <div class="message-unit" [class.right]="!even">
                                    <div class="message-unit-inner">
                                        <span class="profile-pic">
                                            <seech-placeholder [width]="'100%'" [height]="'100%'" [shape]="'circle'"></seech-placeholder>
                                        </span>
                                        <div class="loading-state-bubble">
                                            <seech-placeholder [width]="'100%'" [height]="'10px'"></seech-placeholder>
                                            <seech-placeholder [width]="'100%'" [height]="'10px'"></seech-placeholder>
                                            <seech-placeholder [width]="'100%'" [height]="'10px'" *ngIf="!even"></seech-placeholder>
                                        </div>
                                        <div class="status">
                                            <seech-placeholder [width]="'40px'" [height]="'10px'"></seech-placeholder>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    
                    <ng-template #emptyState>
                        <div class="empty-state">
                            <p class="text">
                                Start a conversation by clicking the input box below.
                            </p>
                        </div>
                    </ng-template>
                </ng-container>
    
                <ng-template #messageArea>
                    <div class="message-box-content-area" #messageAreaContent>
                        <ng-container *ngFor="let message of conversations">
                            <div class="message-unit" [ngClass]="{'right': message.createdBy === userProfile.id}">
                                <div class="message-unit-inner">
                                    <span class="profile-pic" 
                                    [appUserImage]="message.createdBy === userProfile.id ? userProfile : getUserSummaryById(message.createdBy) | convertUserSummaryToUser">
                                    </span>

                                    <ng-container *ngIf="true; else filesTemplate">
                                        <div class="bubble" [ngClass]="{'bg-danger': $any(message).status === 'error'}">
                                            <pre>{{ message.body.trim() }}</pre>
                                        </div>
                                    </ng-container>

                                    <!-- TODO : Clean up and refactor all temp variable usage in this file -->
                                    <ng-template #filesTemplate>
                                        <div class="media" [ngClass]="{'with-caption': tempMedia.body || tempMedia.media.length > 1}">
                                            <div class="media-inner" [appChatImageCollage]="tempMedia" (click)="onMediaChatClick(tempMedia)"></div>
                                            <p class="media-caption" *ngIf="tempMedia.body">{{tempMedia.body}}</p>
                                        </div>

                                        <div class="file">
                                            <img src="" alt="">
                                        </div>
                                    </ng-template>

                                    <div class="status">
                                        <ng-container *ngIf="message.createdOn && !$any(message).status; else notSent">
                                            <ng-container *ngIf="message.createdBy === userProfile.id">
                                                <i [sch-icon]="'double-checkmark'"></i>
                                            </ng-container>
                                            <p>{{message.createdOn | date:'shortTime'}}</p>
                                        </ng-container>

                                        <ng-template #notSent>
                                            <p *ngIf="$any(message).status === 'sending'" class="fst-italic">sending...</p>
                                            <p *ngIf="$any(message).status === 'error'" class="fst-italic text-danger">
                                                Unable to send  
                                                <span (click)="$any(message).status = 'sending'; sendMessage(message)" class="resend"> Resend </span>
                                            </p>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-template>
            </div>
            <div class="message-box-container" appClickOutsideNotifier (outsideClick)="hideMobileOptions()" #inputBox>
                <div class="attachment-container">
                    <div>
                        <div class="icon" (click)="selectFile('camera')">
                            <i [sch-icon]="'add-photo'"></i>
                        </div>
                        <p class="label">Camera</p>
                    </div>

                    <div>
                        <div class="icon" (click)="selectFile('gallery')">
                            <i [sch-icon]="'upload-image'"></i>
                        </div>
                        <p class="label">Upload image</p>
                    </div>

                    <div>
                        <div class="icon" (click)="selectFile('doc')">
                            <i [sch-icon]="'upload-file'"></i>
                        </div>
                        <p class="label">Upload File</p>
                    </div>
                </div>

                <div class="textbox-container">
                    <div class="action-icon outlined" (click)="isMobileOptionsOpen ? hideMobileOptions() : openMobileOptions()">
                        <i [sch-icon]="'plus'"></i>
                    </div>
    
                    <div class="input-wrapper">
                        <div class="input-wrapper-top">
                            <textarea class="text-input" 
                            placeholder="Message" 
                            [(ngModel)]="messageBody"
                            (keydown.enter)="$event.preventDefault()"
                            (keyup.enter)="handleKeyUp($event)"
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="5"></textarea>

                            <div class="send-button">
                                <div class="icon" (click)="sendMessage()">
                                    <i [sch-icon]="'send'"></i>
                                </div>
                            </div>

                        </div>
    
                        <div class="actions-tray">
                            <div class="actions-tray-left">
                                <div class="media-item" (click)="selectFile('gallery')">
                                    <i [sch-icon]="'upload-image'"></i>
                                    <p class="label">Upload image</p>
                                </div>
                                <div class="media-item" (click)="selectFile('doc')">
                                    <i [sch-icon]="'upload-file'"></i>
                                    <p class="label">Upload File</p>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="action-icon">
                        <i [sch-icon]="'mic'"></i>
                    </div>
    
                    <div class="action-icon outlined ps-2" (click)="sendMessage()">
                        <i [sch-icon]="'send'"></i>
                    </div>
                </div>

                <seech-file-upload [acceptMultiple]="true" [maxSizeInMb]="4" [fileTypes]="fileTypes" 
                [capture]="capture" #fileUpload></seech-file-upload>
            </div>

            <div class="media-overlay" *ngIf="selectedFiles">
                <app-media-upload-preview [files]="selectedFiles" (sendMessage)="sendMessageWithMedia($event)" 
                (selectMore)="sendMoreMedia()" (closePreview)="closeMediaPreviewOverlay()"></app-media-upload-preview>
            </div>
        </div>
    </div>
</ng-template>
