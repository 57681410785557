<section id="dashboard-payments">
  <app-bio-summary></app-bio-summary>

  <section class="double-items first-block">
    <div>
      <div class="stat-header">
        <p class="stat-text">Project Statistics</p>

        <seech-dropdown [id]="'dropdown'" [alignment]="'down'" [direction]="'down'" label="Month" [color]="'#00942954'"
          [disabled]="false" [size]="'sm'" [variant]="'outline'" [fontColor]="'#97A2B0'">
          <li *ngFor="let range of dateRanges">
            <a sch-dropdown-item href="javascript:void(0)" (click)="onDateRangeSelect(range.value)"
              [id]="range.label.toLowerCase() + '-item'">
              {{ range.label }}
            </a>
          </li>
        </seech-dropdown>
      </div>

      <div class="card display-cards chart-wrapper">
        <seech-linechart [datasets]="paymentDatasets" [labels]="paymentLabels"
          [lineChartOptions]="paymentOptions"></seech-linechart>
      </div>
    </div>

    <div>
      <div class="stat-header">
        <p class="stat-text">Financial Summary</p>
      </div>

      <div class="card display-cards summary-section">
        <div class="inner-summary">
          <p class="inner-summary-desc">Earnings (YTD)</p>

          <div class="d-flex align-items-center gap-3 my-2 w-100">
            <p class="inner-summary-amount">$00.00</p>

            <div class="inner-summary-indices">
              <p>0%</p>
            </div>
          </div>

          <div class="d-flex align-items-center gap-2 w-100">
            <p class="inner-summary-rise">+$0</p>
            <p class="inner-summary-period">than last month</p>
          </div>
        </div>

        <div class="inner-summary">
          <p class="inner-summary-desc neg-desc">Expenses (YTD)</p>

          <div class="d-flex align-items-center gap-3 my-2 w-100">
            <p class="inner-summary-amount">$00.00</p>

            <div class="inner-summary-indices">
              <p>0%</p>
            </div>
          </div>

          <div class="d-flex align-items-center gap-2 w-100">
            <p class="inner-summary-rise neg-rise">-$0</p>
            <p class="inner-summary-period">than last month</p>
          </div>
        </div>

        <div class="inner-summary">
          <p class="inner-summary-desc">Projected Earnings</p>

          <div class="d-flex align-items-center gap-3 my-2 w-100">
            <p class="inner-summary-amount">$00.00</p>

            <div class="inner-summary-indices">
              <p>0%</p>
            </div>
          </div>

          <div class="d-flex align-items-center gap-2 w-100">
            <p class="inner-summary-rise">+$0</p>
            <p class="inner-summary-period">than last month</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="mb-4">
    <div class="stat-header">
      <p class="stat-text">Transactions</p>

      <button [color]="'#97A2B0'" [variant]="'outline'" class="filter-button" size="lg" sch-button [id]="'filter'">
        <img src="{{ cdnBaseUrl }}images/filter-icon.svg" alt="filter icon" />
        <p>Filter</p>
      </button>
    </div>

    <div class="card display-cards txn-card" [ngClass]="{ 'justify-content-center': transactionBody.length === 0 }">
      <ng-container *ngIf="transactionBody.length === 0">
        <div class="empty-card">
          <p class="empty-card-top">No Transaction</p>
          <p class="empty-card-bottom">You haven't made any transactions yet</p>
        </div>
      </ng-container>

      <ng-container *ngIf="transactionBody.length >= 1">
        <table class="table align-middle mb-0 bg-white">
          <thead class="">
            <tr>
              <th *ngFor="let header of transactionHeaders">
                <span class="table-header">
                  {{ header }}
                </span>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let row of transactionBody">
              <td>
                <p class="txn-desc">{{ row.desc }}</p>
              </td>

              <td>
                <p class="txn-date">{{ row.date }}</p>
              </td>

              <td>
                <p class="txn-id">{{ row.transactionId }}</p>
              </td>

              <td>
                <p class="txn-amount inward" *ngIf="row.transactionStatus === txnStatuses.Credit">
                  +${{ row.amount }}
                </p>
                <p class="txn-amount stalling" *ngIf="row.transactionStatus === txnStatuses.Pending">
                  ${{ row.amount }}
                </p>
                <p class="txn-amount outward" *ngIf="row.transactionStatus === txnStatuses.Debit">
                  -${{ row.amount }}
                </p>
              </td>

              <td>
                <span class="txn-status success-status" *ngIf="row.status === statuses.Received">
                  <p class="txn-status-desc inward">{{ row.statusDesc }}</p>
                </span>
                <span class="txn-status warning-status" *ngIf="row.status === statuses.Pending">
                  <p class="txn-status-desc stalling">{{ row.statusDesc }}</p>
                </span>
                <span class="txn-status error-status" *ngIf="row.status === statuses.Withdraw">
                  <p class="txn-status-desc outward">{{ row.statusDesc }}</p>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </div>
  </div>

  <div class="">
    <div class="stat-header">
      <p class="stat-text">Invoice</p>

      <div class="d-flex align-items-center gap-3">
        <button [color]="'#97A2B0'" [variant]="'outline'" class="filter-button" size="lg" sch-button [id]="'filter'">
          <img src="{{ cdnBaseUrl }}images/filter-icon.svg" alt="filter icon" />
          <p>Filter</p>
        </button>

        <button class="invoice-button" size="lg" sch-button fill="solid" [id]="'add-invoice'">
          <i [sch-icon]="'add-circle'" class=""></i>
          <p>New Invoice</p>
        </button>
      </div>
    </div>

    <div class="card display-cards invoice-card" [ngClass]="{ 'justify-content-center': invoiceBody.length === 0 }">
      <ng-container *ngIf="invoiceBody.length === 0">
        <div class="empty-card">
          <p class="empty-card-top">No Invoices</p>
          <p class="empty-card-bottom">
            Click on "New Invoice" button to add an invoice
          </p>
        </div>
      </ng-container>

      <ng-container *ngIf="invoiceBody.length >= 1">
        <table class="table align-middle mb-0 bg-white">
          <thead class="">
            <tr>
              <th *ngFor="let header of invoiceHeaders">
                <span class="table-header">
                  {{ header }}
                </span>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let row of invoiceBody">
              <td>
                <div class="d-flex align-items-center gap-2">
                  <div>
                    <seech-checkbox [(selected)]="checkboxValue" label="" id="'checkbox'" color="success">
                    </seech-checkbox>
                  </div>
                  <div>
                    <p class="invoice-name">{{ row.name }}</p>
                    <p class="invoice-email">{{ row.email }}</p>
                  </div>
                </div>
              </td>

              <td>
                <div class="d-flex align-items-center gap-2">
                  <i [sch-icon]="'note-stack'" class=""></i>
                  <p class="invoice-id">{{ row.orderId }}</p>
                </div>
              </td>

              <td>
                <p class="invoice-amount">${{ row.amount }}</p>
              </td>

              <td>
                <p class="invoice-date">{{ row.date }}</p>
              </td>

              <td>
                <span class="txn-status invoice-status warning-status" *ngIf="row.status === statuses.Received">
                  <p class="invoice-desc stalling">{{ row.statusDesc }}</p>
                </span>
                <span class="txn-status invoice-status success-status" *ngIf="row.status === statuses.Pending">
                  <p class="invoice-desc inward">{{ row.statusDesc }}</p>
                </span>
                <span class="txn-status invoice-status error-status" *ngIf="row.status === statuses.Withdraw">
                  <p class="invoice-desc outward">{{ row.statusDesc }}</p>
                </span>
              </td>

              <td>
                <i [sch-icon]="'download'" class=""></i>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </div>
  </div>
</section>