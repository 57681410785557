import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { CONSTANT, Message, MessageExtension, MessageRequest, MessageService, ThreadPreview, ThreadResponse, User, UserSummary } from '@profindar/shared-ng';
import { SessionService } from '../../../../services/session.service';
import { Observable, Subscription, map, switchMap } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Guid } from 'typescript-guid';
import { RealtimeService } from '../../../../services/realtime.service';
import { ModalService, ToastService } from '@seech/ux-ng';
import { FileType, FileUploadComponent } from '@seech/controls-ng';
import { Media } from '@seech/media-ng';
import { GalleryCarouselComponent } from '../gallery-carousel/gallery-carousel.component';
import { MediaUploadPreviewComponent } from './media-upload-preview/media-upload-preview.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

type FileSelectionType = 'camera' | 'gallery' | 'doc';

@Component({
  selector: 'app-general-message',
  templateUrl: './general-message.component.html',
  styleUrls: ['./general-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneralMessageComponent implements OnInit, OnChanges, OnDestroy {
  cdnBaseUrl = environment.cdnBaseUrl;
  @ViewChild('messageAreaContent', { static: false }) messageAreaContent!: ElementRef;
  @ViewChild('inputBox') inputBoxRef!: ElementRef;
  @ViewChild('fileUpload') fileUpload!: FileUploadComponent;
  @Output() openProfile: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() goBack: EventEmitter<void> = new EventEmitter<void>();
  @Output() newThreadCreated: EventEmitter<Message> = new EventEmitter<Message>();
  @Output() updateThreadPreview: EventEmitter<Message> = new EventEmitter<Message>();
  @Output() particpantClick: EventEmitter<UserSummary> = new EventEmitter();
  @Input() conversation?: ThreadPreview | null;
  @Input() profileOpen: boolean = false;
  @Input() startingNewThread = false;
  @Input() standAlone = false;
  @Input() projectId?: string;
  conversations?: Message[] | MessageExtension[];
  showParticipants = false;
  loadingRecipients = true;
  sub: Subscription = new Subscription();
  userProfile!: User;
  messageBody: string = '';
  styleDeclaration = <CSSStyleDeclaration>{
    fontSize: '1rem'
  }
  selectFileSub?: Subscription;
  selectedFiles?: FileList;
  selectingFileType?: FileSelectionType;
  imageFiletypes: FileType[] = ['image/*'];
  docFiletypes: FileType[] = ['application/pdf', 'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation'];

  // TODO : Clean up all temp items on this page
  tmpImgSrc1 = 'https://mdbootstrap.com/img/Photos/Vertical/1.webp';
  tmpImgSrc2 = 'https://mdbootstrap.com/img/Photos/Square/1.webp';
  tmpImgSrc3 = 'https://mdbootstrap.com/img/Photos/Slides/2.webp';
  tmpImgSrc4 = 'https://mdbootstrap.com/img/Photos/Slides/img%20(40).webp';
  tmpImgSrc5 = 'https://mdbootstrap.com/img/Photos/Slides/3.webp';
  tempMedia: MessageExtension = <MessageExtension>{
    body: 'I\'m talking about this one. zxjbhhas saugs dsdsydsy dsudsuy',
    media: [
      { type: 'image', src: this.tmpImgSrc1 },
      { type: 'image', src: this.tmpImgSrc2 },
      { type: 'image', src: this.tmpImgSrc3 },
      { type: 'image', src: this.tmpImgSrc4 },
      { type: 'image', src: this.tmpImgSrc5 },
      { type: 'image', src: this.tmpImgSrc1 },
      { type: 'image', src: this.tmpImgSrc2 },
      { type: 'image', src: this.tmpImgSrc3 },
      { type: 'image', src: this.tmpImgSrc4 },
    ]
  }

  constructor(private messageService: MessageService,
    private cdr: ChangeDetectorRef,
    private realtimeService: RealtimeService,
    private toastService: ToastService,
    private modalService: ModalService,
    private breakpointObserver: BreakpointObserver,
    private renderer: Renderer2,
    private sessionService: SessionService) { }

  ngOnInit(): void {
    this.userProfile = this.sessionService.getCurrentUser()!;
    this.messageListener();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const previousValue = changes['conversation']?.previousValue;
    const conversation = changes['conversation']?.currentValue;
    if (previousValue?.message?.threadID != conversation?.message?.threadID) {
      const isPlaceholder = conversation?.message.id === Guid.createEmpty().toString();
      this.conversations = isPlaceholder ? [] : undefined;
      this.loadingRecipients = false;
      this.hideMobileOptions();
      this.messageBody = '';
      if(!isPlaceholder) this.getThread();
    }
    else if (conversation === null) {
      this.loadingRecipients = false;
    }
  }

  get displayIsMobile(){
    return this.breakpointObserver.isMatched(Breakpoints.XSmall);
  }

  messageListener() {
    this.realtimeService.startListener(CONSTANT.REALTIME.METHOD.PUSH, CONSTANT.REALTIME.CHANNEL.MESSAGE)
    this.getMessageRealTime();
  }

  getMessageRealTime() {
    this.realtimeService.subject.subscribe({
      next: (message: Message) => {
        //receive realtime message here, include logic here also
        //if new message is for current conversation
        if (message.threadID === this.conversation?.message.threadID) {
          this.conversations = this.conversations ? [...this.conversations, message] : [message];
          this.scrollToBottom();
        }
        this.updateThreadPreview.emit(message);
        this.cdr.detectChanges();
      }
    })
  }

  get isMobileOptionsOpen(): boolean {
    return this.inputBoxRef.nativeElement.classList.contains('show-options');
  }
  openMobileOptions() {
    if (this.inputBoxRef)
      this.renderer.addClass(this.inputBoxRef.nativeElement, 'show-options');
  }
  hideMobileOptions() {
    if (this.inputBoxRef && this.isMobileOptionsOpen)
      this.renderer.removeClass(this.inputBoxRef.nativeElement, 'show-options');
  }

  get fileTypes(): FileType[]{
    switch (this.selectingFileType) {
      case 'camera':
        return this.imageFiletypes;
      case 'gallery':
        return this.imageFiletypes;
      case 'doc':
        return this.docFiletypes;
      default:
        return [];
    } 
  }
  get capture(){
    return this.selectingFileType === 'camera' ? 'environment' : null;
  }

  selectFile(type: FileSelectionType, editing = false) {
    this.selectFileSub?.unsubscribe();
    this.selectingFileType = type;
    this.cdr.detectChanges();
    this.fileUpload.triggerUpload();

    this.selectFileSub = this.fileUpload.selected.subscribe((event: InputEvent) => {
      this.selectingFileType = undefined;
      const files = (event.target as HTMLInputElement).files;
      if(files){
        this.selectedFiles = files;
        if(type != 'doc' && !editing){
          this.displayIsMobile ? this.onSelectMedia() : null;
        }
      }
    });

  }

  onMediaChatClick(tempMedia: MessageExtension) {
    const mediaItems = tempMedia.media.map(x => {
      return <Media>{
        id: Guid.create().toString(),
        src: x.src,
        type: x.type
      }
    });

    this.modalService.open(GalleryCarouselComponent, {
      data: { mediaItems },
      modalClass: 'modal-dialog-centered modal-lg'
    });
  }

  onSelectMedia() {
    const modalRef = this.modalService.open(MediaUploadPreviewComponent, {
      data: { files: this.selectedFiles },
      modalClass: 'modal-fullscreen-md-down',
      backdrop: false
    });

    modalRef.component.sendMessage.subscribe((event: string) => {
      this.messageBody = event;
      this.sendMessage();
      modalRef.close();
    });
    modalRef.component.selectMore.subscribe(() => {
      this.sendMoreMedia();
    });
    modalRef.component.closePreview.subscribe(() => {
      this.closeMediaPreviewOverlay();
      modalRef.close();
    });
  }

  sendMessageWithMedia(event: string){
    this.messageBody = event;
    this.sendMessage();
    this.closeMediaPreviewOverlay();
  }

  sendMoreMedia(){
    this.selectFile('gallery', true);
  }

  closeMediaPreviewOverlay(){
    this.selectedFiles = undefined;
  }

  getThread() {
    const page = 0;
    const size = 20;
    this.sub.add(
      this.messageService.getMessagesByThreadId(this.conversation!.message.threadID, page, size)
        .pipe(map((res: Message[]) => {
          if (res && res.length > 0)
            return res.sort((a, b) => new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime());
          else return []
        }))
        .subscribe((res: Message[]) => {
          this.conversations = res;
          this.cdr.markForCheck();
          this.scrollToBottom();
        })
    );
  }

  get participantCount(): number {
    const participants = this.conversation?.user;
    return participants?.length ?? 0;
  }

  scrollToBottom() {
    setTimeout(() => {
      if (this.messageAreaContent) {
        const element = this.messageAreaContent.nativeElement as HTMLElement;
        if (element) {
          element.scrollTo({ left: 0, top: element.scrollHeight, behavior: 'smooth' });
        }
      }
    }, 100);
  }

  handleKeyUp(event: any) {
    if (!event.shiftKey && !this.displayIsMobile) {
      this.sendMessage();
    }
  }

  sendMessage(messageToResend?: Message | MessageExtension) {
    if (!this.messageBody.trim() && !messageToResend) return;

    const callBack = (res: Message, placeholderId: string) => {
      if (this.conversation?.message.threadID === res.threadID) {
        const index = this.conversations?.findIndex(x => x.id === placeholderId) ?? -1;
        if (index > -1 && ('status' in this.conversations![index])) {
          this.conversations![index] = res;
        }
      }
    }

    const payload: MessageRequest = {
      id: Guid.createEmpty().toString(),
      threadID: '',
      body: messageToResend ? messageToResend.body : this.messageBody.trim()
    }

    let placeholderConversation: MessageExtension = messageToResend as MessageExtension;
    if (!messageToResend) {
      placeholderConversation = <MessageExtension>{
        id: Guid.create().toString(),
        threadID: this.conversation?.message.threadID,
        body: this.messageBody,
        createdBy: this.userProfile.id,
        status: 'sending'
      };

      this.conversations = this.conversations ? [...this.conversations, placeholderConversation] : [placeholderConversation];
      this.messageBody = '';
      this.scrollToBottom();
    }
    this.cdr.markForCheck();

    // check if its a new message
    if (this.conversation?.message.threadID === Guid.createEmpty().toString()) {
      const participants = this.conversation?.user!.map(x => x.id);
      if(!participants) return;
      let newThread$: Observable<ThreadResponse> = new Observable();
      if (this.standAlone) {
        newThread$ = this.messageService.createStandaloneThread(this.projectId!, participants);
      }
      else if (this.startingNewThread) {
        newThread$ = this.messageService.createThread(participants);
      }

      newThread$.pipe(
        switchMap((response: ThreadResponse) => {
          payload.threadID = response.threadId;
          return this.messageService.sendMessage(payload);
        }),
      ).subscribe(
        (res: Message) => {
          this.newThreadCreated.emit(res);
          callBack(res, placeholderConversation.id);
        }, () => {
          placeholderConversation.status = 'error';
          this.toastService.error('Unable to send message, hit resend to try again');
        }, () => {
          this.cdr.markForCheck();
        });
    }
    else {
      payload.threadID = this.conversation!.message.threadID;
      this.messageService.sendMessage(payload).subscribe(
        (res: Message) => {
          this.updateThreadPreview.emit(res);
          callBack(res, placeholderConversation.id);
        }, () => {
          placeholderConversation.status = 'error';
          this.toastService.error('Unable to send message, hit resend to try again');
        }, () => {
          this.cdr.markForCheck();
        });
    }
  }


  getUserSummaryById(userId: string): UserSummary {
    return this.conversation?.user?.find(x => x.id === userId) ?? {} as UserSummary;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}

