import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { environment } from '../../../../environments/environment';
import { CONSTANT } from '../../../constants';
import { User } from '@profindar/shared-ng';
import { LoaderService } from '../../../services/loader.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @HostBinding('style.height')
  height = '100%';
  cdnBaseUrl = environment.cdnBaseUrl;
  @Output() readonly profileClick: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() readonly exploreClick: EventEmitter<void> =
    new EventEmitter<void>();
  route = CONSTANT.ROUTE;
  @Input({ required: true }) unreadNotificationCount = 0;
  @Input({ required: true }) user: User | null = null;
  @Input({ required: true }) nameLabel = '';
  @Input({ required: true }) unreadMessagesCount = 0;
  @Input({ required: true }) showSidenav!: boolean;
  isLoading = true;

  constructor(
    private loaderService: LoaderService,
    private cdRef: ChangeDetectorRef
  ) {
    this.loaderService.isLoading.subscribe({
      next: (isLoading) => {
        //This problem happened because loaderService.isLoading value was changed in the interceptor before
        //Angular's change detection cycle completes
        //Concerning why I use settimeout here was explained in an official video from Angular
        //https://www.youtube.com/watch?v=O47uUnJjbJc
        setTimeout(() => {
          this.isLoading = isLoading;
          this.cdRef.markForCheck();
        }, 0);
      },
    });
  }

  public get showExplore(): boolean {
    return !this.showSidenav;
  }
}
