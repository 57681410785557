/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { PaymentSchedule } from '../model/models';
import { PaymentScheduleChangeRequest } from '../model/models';
import { PaymentScheduleChangeRequestResponse } from '../model/models';
import { PaymentScheduleResponse } from '../model/models';
import { ResponseToPaymentScheduleChangeRequest } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface PaymentScheduleServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param paymentScheduleId 
     */
    paymentScheduleDeleteScheduleById(paymentScheduleId: string, extraHttpRequestParams?: any): Observable<PaymentSchedule>;

    /**
     * 
     * 
     * @param projectId 
     */
    paymentScheduleGetPaymentScheduleChangeRequestByProjectId(projectId: string, extraHttpRequestParams?: any): Observable<PaymentScheduleChangeRequestResponse>;

    /**
     * 
     * 
     * @param page 
     * @param size 
     */
    paymentScheduleGetSchedule(page?: number, size?: number, extraHttpRequestParams?: any): Observable<Array<PaymentSchedule>>;

    /**
     * 
     * 
     * @param paymentScheduleId 
     */
    paymentScheduleGetScheduleById(paymentScheduleId: string, extraHttpRequestParams?: any): Observable<PaymentScheduleResponse>;

    /**
     * 
     * 
     * @param projectId 
     */
    paymentScheduleGetScheduleByProjectId(projectId: string, extraHttpRequestParams?: any): Observable<PaymentScheduleResponse>;

    /**
     * 
     * 
     * @param paymentSchedules 
     */
    paymentSchedulePaymentScheduleChangeRequest(paymentSchedules: PaymentScheduleChangeRequest, extraHttpRequestParams?: any): Observable<PaymentScheduleChangeRequest>;

    /**
     * 
     * 
     * @param paymentSchedules 
     */
    paymentScheduleRespondToPaymentScheduleChangeRequest(paymentSchedules: ResponseToPaymentScheduleChangeRequest, extraHttpRequestParams?: any): Observable<PaymentScheduleChangeRequest>;

    /**
     * 
     * 
     * @param paymentScheduleId 
     * @param status 
     */
    paymentScheduleUpdatePaymentScheduleStatus(paymentScheduleId: string, status: string, extraHttpRequestParams?: any): Observable<PaymentSchedule>;

}
