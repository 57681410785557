/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { MessageActionProperties, MessageService as MessageClient } from '../../../../generated';
import { MessageRequest } from '../../../../models';
import { Observable } from 'rxjs';
import { ThreadResponse } from 'libs/shared-ng/src/lib/generated/model/threadResponse';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(private messageClient: MessageClient) {}

  /**
   * Creates a new message thread with the specified participants.
   * @param {string[]} participants - An array of participant IDs.
   * @returns {Observable} An Observable that emits a response indicating the success of the operation.
   */
  createThread(participants: string[]) {
    return this.messageClient.messageCreateThread(participants);
  }

  /**
   * Retrieves messages of a thread by its ID.
   * @param {string} threadId - The ID of the message thread.
   * @param {number | undefined} page - The page number of messages to retrieve.
   * @param {number | undefined} size - The size of the page.
   * @returns {Observable<Message[]>} An Observable that emits a response indicating the success of the operation.
   */
  getMessagesByThreadId(threadId: string, page?: number, size?: number) {
    return this.messageClient.messageGetMessagesByThreadId(
      threadId,
      page,
      size
    );
  }

  /**
   * Retrieves threadPreview of a thread by username of recipient.
   * @param {string} username - The ID of the recipient.
   * @returns {Observable<ThreadPreview>} An Observable that emits a response indicating the success of the operation.
   */
  getUserThreadPreview(username: string) {
    return this.messageClient.messageGetUserThreadPreview(username);
  }

  /**
   * Retrieves threadPreview of a thread by its ID.
   * @param {string} threadId - The ID of the thread.
   * @returns {Observable<ThreadPreview>} An Observable that emits a response indicating the success of the operation.
   */
  getUserThreadPreviewByThreadId(threadId: string) {
    return this.messageClient.messageGetUserThreadPreviewByThreadId(threadId);
  }

  /**
   * Retrieves threadPreview of a thread by its ID.
   * @param {string} threadId - The ID of the thread.
   * @returns {Observable<ThreadDetails>} An Observable that emits a response indicating the success of the operation.
   */
  getThreadDetails(threadId: string) {
    return this.messageClient.messageGetThreadDetails(threadId);
  }

  /**
   * Searches for recipients based on the provided search word.
   * @param {string | undefined} searchWord - The search term to filter recipients.
   * @param {number | undefined} page - The page number of recipients to retrieve.
   * @param {number | undefined} size - The size of the page.
   * @returns {Observable<ThreadPreview[]>} An Observable that emits a response indicating the success of the operation.
   */
  getRecepientListSearch(searchWord?: string, page?: number, size?: number) {
    return this.messageClient.messageGetRecepientListSearch(
      searchWord,
      page,
      size
    );
  }

  /**
   * Sends a message.
   * @param {string} threadId - The threadId of the thread to block.
   * @returns {Observable<boolean>} An Observable that emits a response indicating the success of the operation.
   */
  leaveGroup(threadId: string) {
    return this.messageClient.messageLeaveGroup(threadId);
  }

  /**
   * Sends a message.
   * @param {MessageRequest} message - The message to be sent.
   * @returns {Observable<Message>} An Observable that emits a response indicating the success of the operation.
   */
  sendMessage(message: MessageRequest) {
    return this.messageClient.messageSendMessage(message);
  }

  /**
   * Updates the last seen timestamp of a thread.
   * @param {string} threadId - The ID of the message thread.
   * @returns {Observable<void>} An Observable that emits a response indicating the success of the operation.
   */
  updateLastSeen(threadId: string) {
    return this.messageClient.messageUpdateLastSeen(threadId);
  }

  /**
   * Handles actions being carried out on messages, like blocking and notification actions.
   * @param {MessageActionProperties} messageActionProperties - The properties of the thread.
   * @returns {Observable<boolean>} An Observable that emits a response indicating the success of the operation.
   */
  actionsHandler(messageActionProperties: MessageActionProperties) {
    return this.messageClient.messageMessageActionsHandler(messageActionProperties);
  }

  /**
   * Retrieves the number of unread messages for the current user.
   * @returns {Observable<number>} An Observable that emits the number of unread messages.
   */
  getUserUnReadMessages(): Observable<number> {
    return this.messageClient.messageGetUserUnReadMessages();
  }

  /**
   * Creates a standalone message thread for a project with specified participants.
   * @param {string} projectId - The ID of the project.
   * @param {string[]} participants - An array of participant IDs.
   * @returns {Observable<string>} An Observable that emits the ID of the created thread.
   */
  createStandaloneThread(
    projectId: string,
    participants: string[]
  ): Observable<ThreadResponse> {
    return this.messageClient.messageCreateStandaloneThread(
      projectId,
      participants
    );
  }
}
