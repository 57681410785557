<div class="d-flex justify-content-center">
  <div class="bid-dialog-container">

    <ng-template #bidTemplate>
      <div class="bid-container d-flex justify-center align-items-center flex-column">

        <div class="form-input-container">
          <label for="services" class="form-label">Services</label>
          <div class="chips-container2">
            <seech-chips [values]="services" [fill]="'solid'" [variant]="'single-colored'"
              color="success" [config]="this.config" [id]="'second-ux-chips'"></seech-chips>
          </div>
        </div>

        <div class="form-input-container">
          <label for="services" class="form-label">Tasks</label>
          <div class="chips-container2 d-flex flex-wrap p-2 pb-0">
            <app-task-summary [displayLimit]="6" [tasks]="tasks" overflowY="scroll"></app-task-summary>
          </div>
        </div>

        <ng-container *ngIf="bidResponse">

          <div class="form-input-container pt-3">

            <div class="d-flex gap-3 ">
              <div class="bid-form-border w-50" [class.thick-border]="userIsClient">
                <div class="project-owner border-secondary"
                  [ngClass]="{'bg-secondary text-white':userIsClient, 'bg-body text-secondary':!userIsClient}">
                  Project Owner
                </div>
                <app-bid-form #clientBidForm="bidForm" [userIsClient]="userIsClient" [readonly]="!userIsClient"
                  (messageFieldHeightChange)="professionalBidForm.updateMessageFieldHeight($event)"
                  [currency]="currency" [payRateType]="payRateType" [bid]="clientBid"
                  [showMore]="showMoreFields"></app-bid-form>

                <div *ngIf="userIsClient">

                  <span *ngIf="professionalBid?.bidStatus?.name === 'accepted'" class="accepted-label">Bid
                    has been accepted</span>

                  <div *ngIf="professionalBid?.bidStatus?.name !== 'accepted'" class="btn-group shadow-none w-100"
                    #counterTarget="cdkOverlayOrigin" cdkOverlayOrigin>
                    <button class="btn secondary" (click)="counterBid(clientBidForm, $event)">Counter</button>
                    <ng-container *ngIf="!noClientBid()">
                      <div class="vr"></div>
                      <button (click)="bidSubmitDrop = !bidSubmitDrop" [class.show]="bidSubmitDrop"
                        class="btn secondary dropdown-toggle dropdown-toggle-split"
                        [attr.data-bs-toggle]="bidSubmitDrop ? 'dropdown' : null" [attr.aria-expanded]="bidSubmitDrop">
                      </button>
                      <ng-template cdkConnectedOverlay cdkConnectedOverlayHasBackdrop="true"
                        cdkConnectedOverlayBackdropClass="bg-transparent" (backdropClick)="bidSubmitDrop = false"
                        [cdkConnectedOverlayOrigin]="counterTarget" [cdkConnectedOverlayOpen]="bidSubmitDrop">
                        <ul [class.show]="bidSubmitDrop">
                          <li class="dropdown-item bg-body widthdraw"
                            (click)="withdrawBid(clientBid.id); bidSubmitDrop=false">
                            <span sch-icon="bid-outline" class="icon"></span>
                            <span>Withdraw</span>
                          </li>
                        </ul>
                      </ng-template>
                    </ng-container>
                  </div>

                </div>

                <div *ngIf="!userIsClient">

                  <ng-container *ngIf="!noProfessionalBid()">

                    <ng-container *ngIf="professionalBid?.bidStatus?.name !== 'accepted'">
                      <div *ngIf="!noClientBid()" class="btn-group shadow-none w-100" #acceptTarget="cdkOverlayOrigin"
                        cdkOverlayOrigin>
                        <button class="btn accept" (click)="acceptBid(professionalBid, $event)">Accept</button>
                        <button (click)="bidAcceptDrop = !bidAcceptDrop" [class.show]="bidAcceptDrop"
                          class="btn accept dropdown-toggle dropdown-toggle-split"
                          [attr.data-bs-toggle]="bidAcceptDrop ? 'dropdown' : null"
                          [attr.aria-expanded]="bidAcceptDrop">
                        </button>
                        <ng-template cdkConnectedOverlay cdkConnectedOverlayHasBackdrop="true"
                          cdkConnectedOverlayBackdropClass="bg-transparent" (backdropClick)="bidAcceptDrop=false"
                          [cdkConnectedOverlayOrigin]="acceptTarget" [cdkConnectedOverlayOpen]="bidAcceptDrop">
                          <ul [class.show]="bidAcceptDrop">
                            <li class="dropdown-item bg-body"
                              (click)="declineBid(professionalBid); bidAcceptDrop=false">
                              <span sch-icon="cancel" class="icon"></span>
                              <span>Decline</span>
                            </li>
                          </ul>
                        </ng-template>
                      </div>
                    </ng-container>

                  </ng-container>


                  <ng-container *ngIf="noProfessionalBid()">
                    <div
                      class="form-check-rounded d-flex gap-1 justify-content-start align-items-center align-content-center">
                      <input class="form-check-input"
                        (change)="jobTermsChanged($event, professionalBidForm, clientBidForm)" type="checkbox" value=""
                        id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault">
                        Accept "As Is"
                      </label>
                    </div>
                  </ng-container>

                </div>
              </div>

              <div class="bid-form-border w-50" [class.thick-border]="!userIsClient">
                <div class="border-secondary align-self-start biders-name"
                  [ngClass]="{'bg-secondary text-white':!userIsClient, 'bg-body text-secondary':userIsClient}">
                  <span class="single-line text-center">
                    {{professionalName }} {{ !userIsClient ? "(You)": ""}}
                  </span>
                </div>
                <app-bid-form #professionalBidForm="bidForm"
                  (messageFieldHeightChange)="clientBidForm.updateMessageFieldHeight($event)"
                  [userIsClient]="userIsClient" [currency]="currency" [readonly]="userIsClient"
                  [payRateType]="payRateType" [bid]="professionalBid" [showMore]="showMoreFields"
                  type="professional"></app-bid-form>

                <div *ngIf="userIsClient">

                  <div *ngIf="professionalBid?.bidStatus?.name !== 'accepted'" class="btn-group shadow-none w-100"
                    #acceptTarget="cdkOverlayOrigin" cdkOverlayOrigin>
                    <button class="btn accept" (click)="acceptBid(professionalBid, $event)">Accept</button>
                    <button (click)="bidAcceptDrop = !bidAcceptDrop" [class.show]="bidAcceptDrop"
                      class="btn accept dropdown-toggle dropdown-toggle-split"
                      [attr.data-bs-toggle]="bidAcceptDrop ? 'dropdown' : null" [attr.aria-expanded]="bidAcceptDrop">
                    </button>
                    <ng-template cdkConnectedOverlay cdkConnectedOverlayHasBackdrop="true"
                      cdkConnectedOverlayBackdropClass="bg-transparent" (backdropClick)="bidAcceptDrop=false"
                      [cdkConnectedOverlayOrigin]="acceptTarget" [cdkConnectedOverlayOpen]="bidAcceptDrop">
                      <ul [class.show]="bidAcceptDrop">
                        <li class="dropdown-item bg-body" (click)="declineBid(professionalBid); bidAcceptDrop=false">
                          <span sch-icon="cancel" class="icon"></span>
                          <span>Decline</span>
                        </li>
                      </ul>
                    </ng-template>
                  </div>

                </div>

                <div *ngIf="!userIsClient">

                  <span *ngIf="professionalBid?.bidStatus?.name === 'accepted'" class="accepted-label">Bid has
                    been accepted</span>

                  <div *ngIf="noProfessionalBid()">
                    <button (click)="submitBid(professionalBidForm, $event)" sch-button color="secondary"
                      class="bg-secondary submit-btn text-white w-100">
                      Submit
                    </button>
                  </div>

                  <div *ngIf="!noProfessionalBid()" class="d-flex justify-content-between align-items-baseline">
                    <ng-container *ngIf="professionalBid?.bidStatus?.name !== 'accepted'">
                      <div class="btn-group shadow-none w-100" #submitTarget="cdkOverlayOrigin" cdkOverlayOrigin>
                        <button class="btn secondary" (click)="submitBid(professionalBidForm, $event)">Submit</button>
                        <div class="vr"></div>
                        <button (click)="bidSubmitDrop = !bidSubmitDrop" [class.show]="bidSubmitDrop"
                          class="btn secondary dropdown-toggle dropdown-toggle-split"
                          [attr.data-bs-toggle]="bidSubmitDrop ? 'dropdown' : null"
                          [attr.aria-expanded]="bidSubmitDrop">
                        </button>
                        <ng-template cdkConnectedOverlay cdkConnectedOverlayHasBackdrop="true"
                          cdkConnectedOverlayBackdropClass="bg-transparent" (backdropClick)="bidSubmitDrop = false"
                          [cdkConnectedOverlayOrigin]="submitTarget" [cdkConnectedOverlayOpen]="bidSubmitDrop">
                          <ul [class.show]="bidSubmitDrop">
                            <li class="dropdown-item widthdraw bg-body"
                              (click)="withdrawBid(professionalBid.id);bidSubmitDrop=false"> <span
                                sch-icon="bid-outline" class="icon"></span>
                              <span>Withdraw</span>
                            </li>
                          </ul>
                        </ng-template>
                      </div>
                    </ng-container>
                  </div>

                </div>
              </div>
            </div>

          </div>


          <div class="d-flex justify-content-between w-100 px-2">
            <div class="d-flex justify-content-center align-items-center">
              <button class="text-primary btn-collapse" (click)="showMoreFields = !showMoreFields">{{showMoreFields ?
                'See less':'See more' }} </button>
              <i [sch-icon]="showMoreFields ? 'expand' : 'collapse'" class="icon-collapse cursor-pointer"></i>
            </div>

            <button (click)="closeDialog()" sch-button fill="outline" class="cancel-btn">
              Cancel
            </button>
          </div>

        </ng-container>

      </div>
    </ng-template>

    <app-general-modal *ngIf="showDialogTitle" label="Create Bid" padding="0" paddingTop="1rem">
      <ng-container *ngTemplateOutlet="bidTemplate"></ng-container>
    </app-general-modal>

    <ng-container *ngIf="!showDialogTitle">
      <ng-container *ngTemplateOutlet="bidTemplate"></ng-container>
    </ng-container>

  </div>
</div>