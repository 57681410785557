import { Component, OnInit } from '@angular/core';
import { Media } from '@seech/media-ng';

@Component({
  selector: 'app-gallery-carousel',
  templateUrl: './gallery-carousel.component.html',
  styleUrls: ['./gallery-carousel.component.scss'],
})
export class GalleryCarouselComponent implements OnInit {
  mediaItems: Media[] = [];
  selectedMedia?: Media;
  activeMedia = 0; 
  controls = true; 
  indicators = true; 

  ngOnInit(): void {
    if(this.selectedMedia){
      const index = this.mediaItems.findIndex(x => x.id === this.selectedMedia?.id);
      this.activeMedia = index > 0 ? index : 0;
    }

    if(this.mediaItems.length <= 1) this.controls = this.indicators = false;
  }
  
}
