/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable no-debugger */
import { Injectable } from '@angular/core';
import { OnboardingService as OnboardingClient, OnBoardingResponse } from '../../../../generated';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class OnboardingService {
  constructor(private onboardingClient: OnboardingClient) {}

  addUserIntent(intentId: string): Observable<OnBoardingResponse> {
    return this.onboardingClient.onboardingAddUserIntent(intentId);
  }

  addServicesOfInterest(serviceIds: Array<string>): Observable<OnBoardingResponse> {
    return this.onboardingClient.onboardingAddServicesOfInterest(serviceIds);
  }
}
