import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthNgModule, AuthService, SessionService, AuthInterceptor, ACCOUNT_SERVICE_TOKEN } from '@seech/auth-ng';
import { CoreNgModule } from '@seech/core-ng';
import { ControlsNgModule } from '@seech/controls-ng';
import { IconsNgModule } from '@seech/icons-ng';
import { LayoutModule } from './layout';
import { UxNgModule } from '@seech/ux-ng';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AuthRoutingModule } from './auth/auth-routing.module';
import { AuthModule } from './auth/auth.module';
import { AppRoutingModule } from './app-routing.module';
import { ProtectedRoutingModule } from './protected/protected-routing.module';
import { ProtectedModule } from './protected/protected.module';
import { AccountService, Session, SharedNgModule, Token, User } from '@profindar/shared-ng';
import { GlobalProgressBarDirective } from './directives';
import { environment } from '../environments/environment';
import { HttpProgressInterceptorService } from './interceptors/http-progress-interceptor.service';
import { OnboardingModule } from './modules/features/onboarding/onboarding.module';

const HttpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpProgressInterceptorService, multi: true },
  // { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor<User, Session, Token>, multi: true }, // global interceptor
  // { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }, // local interceptor
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    SharedNgModule,
    ControlsNgModule,
    LayoutModule,
    IconsNgModule,
    BrowserAnimationsModule,
    UxNgModule,
    DragDropModule,
    GlobalProgressBarDirective,
    AuthRoutingModule,
    AuthModule,
    ProtectedRoutingModule,
    ProtectedModule,
    OnboardingModule,
    AppRoutingModule,
    CoreNgModule.forRoot({
      BASE_API_URL: environment.BASE_API_URL,
      REALTIME_HUB_URL: 'central-socket',
    }),
    ControlsNgModule.forRoot({
      googleAPIKey: environment.GOOGLE_API_KEY,
    }),
    AuthNgModule.forRoot({
      AUTH_API_URL: environment.AUTH_API_URL,
      AUTH_WEB_URL: environment.AUTH_WEB_URL,
      BASE_API_URL: environment.BASE_API_URL,
      CLIENT_ID: environment.CLIENT_ID,
      SESSION_TIMEOUT: { INTERVAL: 300000, COUNTDOWN: 60000 },
    })
  ],
  providers: [
    { provide: ACCOUNT_SERVICE_TOKEN, useClass: AccountService },
    ...HttpInterceptorProviders
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
