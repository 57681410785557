import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnboardingComponent } from './onboarding.component';
import { ComponentsModule } from '../../general/components/components.module';
import { IconsNgModule } from '@seech/icons-ng';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '../../../directives';
import { ControlsNgModule } from '@seech/controls-ng';
import { UxNgModule } from '@seech/ux-ng';



@NgModule({
  declarations: [ OnboardingComponent ],
  imports: [
    CommonModule,
    ComponentsModule,
    IconsNgModule,
    FormsModule,
    DirectivesModule,
    ControlsNgModule,
    ReactiveFormsModule,
    UxNgModule,
  ]
})
export class OnboardingModule { }
