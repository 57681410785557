/**
 * Profindar API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaymentInstallment } from './paymentInstallment';
import { RequestedTask2 } from './requestedTask2';


export interface TaskChangeRequest { 
    requestedTask: RequestedTask2;
    id: string;
    projectId: string;
    installmentId: string;
    requestedTaskId: string;
    actionType: string;
    status: string;
    createdOn: Date;
    createdBy: string;
    processedOn?: Date;
    processedBy?: string;
    installment: PaymentInstallment;
}

