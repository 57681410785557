import { Injectable } from '@angular/core';
import { Session, User } from '@profindar/shared-ng';
import { StorageService } from '@seech/core-ng';
import { AuthConfigService, SessionService as BaseSessionService } from '@seech/auth-ng';

@Injectable({
  providedIn: 'root',
})
export class SessionService extends BaseSessionService<User, Session> {

  constructor(authConfigService: AuthConfigService, storageService: StorageService) {
    super(authConfigService, storageService);
  }

  getCurrentUserId() {
    return super.getCurrentUser()?.id;
  }

  getCurrentUsername() {
    return super.getCurrentUser()?.username;
  }

  getCurrentUserFullName() {
    return super.getCurrentUser()?.name;
  }

  getCurrentUserImageURL() {
    return super.getCurrentUser()?.imageURL;
  }

  getCurrentUserBio() {
    return super.getCurrentUser()?.bio;
  }

  getCurrentUserPhoneNumber() {
    return super.getCurrentUser()?.phoneNumber;
  }
}
